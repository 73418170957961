import React, { useState, useEffect, useRef } from "react";
import { Card, Form, Input, Col, Row, Select, InputNumber, Button, Table, Modal, DatePicker } from "antd";
import axios from 'axios';
import { SearchOutlined, PrinterOutlined } from '@ant-design/icons'; 
import dayjs from 'dayjs';
import ReactToPrint from 'react-to-print';


const PrintableInvoice = React.forwardRef(({ invoice }, ref) => {
    return (
      <div ref={ref} className="invoice-container">
        <div className="invoice-header">
        <p> {invoice?.selectedOption}</p>
        <p> {invoice?.numberValue} Personas</p>
            <p>Fecha: {dayjs().format('MM/DD/YYYY')}</p>
         
        </div>
  
  
        <div className="invoice-details">
        <div className="payment-info">
        <div className="business-info">
        <h2>Proveedor</h2>
          <p> {invoice?.providerName}</p>
          <p> {invoice?.providerAddress}</p>
          <p> {invoice?.providerID}</p>
          </div>
          </div>
          <div className="bill-to">
            <h2>Cliente</h2>
            <p> {invoice?.clientName}</p>

            <p> {invoice?.clientAddress}</p>
            
          </div>
         
        </div>
  
        <div className="invoice-items">
        <h2>Items</h2>
        {/* If notes is just a simple text field, display it directly */}
        <div style={{maxWidth: '700px', wordWrap: 'break-word'}}>
          {invoice?.notes}
        </div>
      </div>
  
        <div className="invoice-summary">
          <p>Precio Individual: ${invoice?.individualPrice}</p>
          <p>Subtotal: ${invoice?.subtotal}</p>
          <p>Tax: ${invoice?.tax}</p>
          <p>Total Due: ${invoice?.totalDue}</p>
        </div>
  
        <div className="invoice-footer">
        Arancibiaeventos.cl
        </div>
      </div>
    );
  });

const InvoiceForm = () => {
  // State variables remain the same
  const [notes, setNotes] = useState("");
  const [clientName, setClientName] = useState("");
  const [clientAddress, setClientAddress] = useState("");
  const [clientID, setClientID] = useState("");
  const [providerName, setProviderName] = useState("");
  const [providerAddress, setProviderAddress] = useState("");
  const [providerID, setProviderID] = useState("");
  const [selectedOption, setSelectedOption] = useState('');
  const [numberValue, setNumberValue] = useState(0);
  const [individualPrice, setIndividualPrice] = useState(0);


  const [invoices, setInvoices] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [eventDate, setEventDate] = useState(dayjs());


  const [form] = Form.useForm();
  const componentRef = useRef();

  const [subtotal, setSubtotal] = useState(0);
  const [tax, setTax] = useState(0);
 

  const handleSubmit = async (formData) => {
    const formattedDate = formData.eventDate ? formData.eventDate.format('YYYY-MM-DD') : null;
    const invoiceData = {
      ...formData,
      eventDate: formattedDate,
    };
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_NODE_END_POINT}/presupuestos/generar-presupuesto`, invoiceData);
      console.log('Invoice saved:', response.data);
      // Handle success (e.g., showing a success message or clearing the form)
    } catch (error) {
      console.error('Error saving invoice:', error);
      // Handle error (e.g., showing an error message)
    }
  };
  

  useEffect(() => {
    // Set the form's initial values, especially for the eventDate
    form.setFieldsValue({
      eventDate: eventDate,
    });
  }, [form, eventDate]);

  const dropdownOptions = [
    { label: 'Coffe', value: 'coffe' },
    { label: 'Cocktail', value: 'cocktail' },
    { label: 'Desayuno', value: 'desayuno' },
    { label: 'Matrimonio', value: 'matrimonio' },
    { label: 'Despedida Colegio', value: 'despedida-colegio' },
    { label: 'Cumpleaños', value: 'cumpleaños' },
    { label: 'Otro', value: 'otro' },
  ];


  const formatNumber = (number) => {
    return new Intl.NumberFormat('de-DE').format(number);
  };
  
  const columns = [
    {
        title: 'Cliente',
        dataIndex: 'clientName',
        key: 'clientName',
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Buscar Cliente"
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => confirm()}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => confirm()}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Buscar
            </Button>
            <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ),
        onFilter: (value, record) => record.clientName.toString().toLowerCase().includes(value.toLowerCase()),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      },
    {
        title: 'Servicio',
        dataIndex: 'selectedOption',
        key: 'selectedOption',
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Search Servicio"
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => confirm()}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => confirm()}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ),
        onFilter: (value, record) => record.selectedOption.toString().toLowerCase().includes(value.toLowerCase()),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      },
    {
      title: 'Cantidad de Personas',
      dataIndex: 'numberValue',
      key: 'numberValue',
    },
    {
        title: 'Fecha',
        dataIndex: 'eventDate',
        key: 'eventDate',
        defaultSortOrder: 'descend', // or 'ascend'
        sorter: (a, b) => dayjs(a.eventDate).unix() - dayjs(b.eventDate).unix(),
        render: text => text ? dayjs(text).format('DD/MM/YYYY') : '',
      },

    {
      title: 'Acciones',
      key: 'actions',
      render: (_, record) => (
        <Button onClick={() => showModal(record)}>Ver Detalles</Button>
      ),
    },
  ];

  const showModal = (invoice) => {
    setSelectedInvoice(invoice);
    setIsModalVisible(true);
  };


  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const fetchInvoices = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_NODE_END_POINT}/presupuestos/fetchInvoices`);
      setInvoices(response.data);
    } catch (error) {
      console.error('Error fetching invoices:', error);
      // Handle errors
    }
  };

  const populateFormData = () => {
    if (selectedInvoice) {
      // Populate the form using form.setFieldsValue
      form.setFieldsValue({
        notes: selectedInvoice.notes,
        clientName: selectedInvoice.clientName,
        clientAddress: selectedInvoice.clientAddress,
        clientID: selectedInvoice.clientID,
        providerName: selectedInvoice.providerName,
        providerAddress: selectedInvoice.providerAddress,
        providerID: selectedInvoice.providerID,
        selectedOption: selectedInvoice.selectedOption,
        numberValue: selectedInvoice.numberValue,
        individualPrice: selectedInvoice.individualPrice,
       
      });
  
      setNumberValue(selectedInvoice.numberValue);
      setIndividualPrice(selectedInvoice.individualPrice);
      setEventDate(dayjs(selectedInvoice.eventDate));
  
      setIsModalVisible(false);
    }
  };
  
 
  useEffect(() => {
    const calculatedSubtotal = individualPrice * numberValue;
    const calculatedTax = calculatedSubtotal * 0.19; // Assuming 19% tax
    const totalDue = calculatedSubtotal + calculatedTax;
  
    setSubtotal(calculatedSubtotal);
    setTax(calculatedTax);
    // Update selectedInvoice with the new calculations for printing
    setSelectedInvoice(current => ({
      ...current,
      subtotal: calculatedSubtotal,
      tax: calculatedTax,
      totalDue: totalDue
    }));
  }, [individualPrice, numberValue]);
  

  // Call fetchInvoices when the component mounts
  useEffect(() => {
    fetchInvoices();
  }, []);

  return (
    <div>
        
    <Card>
        
      <Row gutter={16}>
        <Col span={24}>
          <Card title="Presupuesto">
          <Form form={form} layout="vertical" onFinish={handleSubmit}>
              <Row gutter={16}>
                {/* Provider Information on the Left */}
                <Col span={12}>
                  <Form.Item label="Proveedor" name="providerName">
                    <Input
                      value={providerName}
                      onChange={(e) => setProviderName(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item label="RUT Proveedor" name="providerID">
                    <Input
                      value={providerID}
                      onChange={(e) => setProviderID(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item label="Dirección" name="providerAddress">
                    <Input
                      value={providerAddress}
                      onChange={(e) => setProviderAddress(e.target.value)}
                    />
                  </Form.Item>
                </Col>

                {/* Client Information on the Right */}
                <Col span={12}>
                  <Form.Item label="Nombre Cliente" name="clientName">
                    <Input
                      value={clientName}
                      onChange={(e) => setClientName(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item label="RUT Cliente" name="clientID">
                    <Input
                      value={clientID}
                      onChange={(e) => setClientID(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item label="Dirección Cliente" name="clientAddress">
                    <Input
                      value={clientAddress}
                      onChange={(e) => setClientAddress(e.target.value)}
                    />
                  </Form.Item>
                  
                </Col>
           
               
                <Col span={24}>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="Servicio" name="selectedOption">
                        <Select
                          placeholder="Seleccionar"
                          value={selectedOption}
                          onChange={(value) => setSelectedOption(value)}
                        >
                          {dropdownOptions.map(option => (
                            <Select.Option key={option.value} value={option.value}>
                              {option.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
     <Form.Item name="eventDate" label="Fecha del Evento">
        <DatePicker
          style={{ width: '100%' }}
          format="YYYY-MM-DD"
          value={eventDate}
          onChange={(date) => setEventDate(date)}
        />
      </Form.Item>



                    </Col>
                    <Col span={12}>
                      <Form.Item label="Cantidad de Personas" name="numberValue">
                        <InputNumber
                          min={0}
                          max={100}
                          value={numberValue}
                          onChange={setNumberValue}
                        />
                      </Form.Item>
                      
                    </Col>
                  </Row>
                 
   
  
                  <Form.Item label="Notas" name="notes" >
                    <Input.TextArea
                    style={{ height: '900px' }}
                      rows={4}
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                    />
                  </Form.Item>
                  <Button size="large" type="primary" htmlType="submit">
              Guardar Presupuesto
            </Button>
            <div style={{ display: "none" }}>
        <PrintableInvoice ref={componentRef} invoice={selectedInvoice} />
      </div>

      {/* ReactToPrint Trigger */}
      <ReactToPrint
        trigger={() => <Button type="link" size="large"><PrinterOutlined /></Button>}
        content={() => componentRef.current}
      />

                  </Col>
              </Row>
          
      
        {/* Add individualPrice input field */}
        <Col span={12}>
                    <Form.Item label="Precio Individual" name="individualPrice">
                      <InputNumber
                        min={0}
                        value={individualPrice}
                        onChange={setIndividualPrice}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                    
                  </Col>
                  </Form>
                  <Col span={12}>
                  <p>Subtotal: ${formatNumber(subtotal)}</p>
<p>IVA (19%): ${formatNumber(tax)}</p>
<p>Total: ${formatNumber(subtotal + tax)}</p>
                  </Col>
          </Card>
          <Table dataSource={invoices} columns={columns} rowKey="_id" />
          <Modal width={1000}  title="Detalles Presupuesto" visible={isModalVisible} onOk={populateFormData} onCancel={handleCancel}>
        <p>Nombre Cliente: {selectedInvoice?.clientName}</p>
        <p>Dirección Cliente: {selectedInvoice?.clientAddress}</p>
        <p>Nombre Proveedor: {selectedInvoice?.providerName}</p>
        <p>Dirección Proveedor: {selectedInvoice?.providerAddress}</p>
        <p>Detalle: {selectedInvoice?.notes}</p>
        <p>Valor por Persona: {selectedInvoice?.individualPrice}</p>
     
        
      </Modal>
        </Col>
      </Row>
    </Card>

    </div>

  );
};

export default InvoiceForm;
