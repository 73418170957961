import React, { useState, useEffect,  forwardRef  } from "react";
import dayjs from 'dayjs';

import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const PrintDetails = ({ orderId, forwardedRef }) => {
    const [orderDetails, setOrderDetails] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(null);

 
  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${process.env.REACT_APP_NODE_END_POINT}/ordenes/${orderId}`);
        const data = await response.json();
        setOrderDetails(data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching order details:", err);
        setError(err.message);
        setLoading(false);
      }
    };

    if (orderId) {
      fetchOrderDetails();
    }
  }, [orderId]);

  let subtotalSum, totalSum;
  if (orderDetails) {
    subtotalSum = orderDetails.formData.tableData.reduce((acc, item) => acc + Number(item.subtotal), 0);
    totalSum = subtotalSum + Number(orderDetails.formData.selectedValue.value);
  }
  
  const timezoneOffset = process.env.REACT_APP_TIMEZONE_OFFSET * 60|| 0;

  const adjustTimezone = (time) => {
      return dayjs(time).utcOffset(timezoneOffset).format('HH:mm');
  };

  return (
    <div ref={forwardedRef}>
      {isLoading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      {orderDetails && (
        
        <div>
            
          <p>Nombre: {orderDetails.formData.Nombre} {orderDetails.formData.businessData.nombreFantasia} </p>
          <p>Dirección: {orderDetails.formData.address}</p>
          <p>Rango: {adjustTimezone(orderDetails.formData.timeRange[0])} {adjustTimezone(orderDetails.formData.timeRange[1])}</p>
          <p>Hora Despacho: {adjustTimezone(orderDetails.formData.deliveryTime)}</p>
          <p>Metodo de pago: {orderDetails.formData.paymentMethod}</p>
          <p>Número: {orderDetails.formData.phoneNumber}</p>
          <p>Notas: {orderDetails.formData.notes}</p>
          <h4>Productos</h4>
          <ul>
            {orderDetails.formData.tableData.map((item) => (
              <li key={item.key}>
                <p><strong>{item.quantity} </strong>{item.name} {item.variant} ${item.subtotal}</p>
            
              </li>
            ))}
          </ul>
          <p>Valor Despacho: ${orderDetails.formData.selectedValue.value}</p>
           <p><strong>Total:</strong> ${totalSum}</p>
        </div>
      )}
    </div>
  );
};

export default forwardRef((props, ref) => <PrintDetails {...props} forwardedRef={ref} />);