import React, { useEffect, useState, useRef } from "react";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom'; 
import {
  Row,
  Col,
  Card,
  Button,
  Avatar,
  Typography,
  Table,
  Modal,
  Pagination,
  Input,
  Space,
  Popconfirm,
} from "antd";
import { SearchOutlined } from '@ant-design/icons';
import Edit from '../components/clientes/editNegocios';
import BusinessForm from '../components/clientes/businessForm';

const { Title } = Typography;

function BusinessesList() {
    const history = useHistory(); 
  const [records, setRecords] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [isNewClientModalVisible, setIsNewClientModalVisible] = useState(false);

  useEffect(() => {
    async function getRecords() {
      const response = await fetch(`${process.env.REACT_APP_NODE_END_POINT}/businesses/`);
      if (!response.ok) {
        const message = `An error occurred: ${response.statusText}`;
        window.alert(message);
        return;
      }

      const fetchedRecords = await response.json();
      setRecords(fetchedRecords);
    }

    getRecords();
  }, []);


  
  const showNewClientModal = () => {
    setCurrentRecord(null); // setting to null to signify a new client creation
    setIsNewClientModalVisible(true);
  };

  // Function to handle newly created client
  const handleCreate = (newClient) => {
    setRecords(prevRecords => [...prevRecords, newClient]);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => 
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
  });

  const columns = [
    {
      title: 'Nombre Fantasia',
      dataIndex: 'nombreFantasia',
      key: 'nombreFantasia',
      ...getColumnSearchProps('nombreFantasia'),
    },
    {
      title: 'Número Contacto',
      dataIndex: 'numeroContacto',
      key: 'numeroContacto',
      ...getColumnSearchProps('numeroContacto'),
    },
    {
      title: 'RUT Empresa',
      dataIndex: 'rutEmpresa',
      key: 'rutEmpresa',
      ...getColumnSearchProps('rutEmpresa'),
    },
    {
      title: 'Correo Contacto',
      dataIndex: 'correoContacto',
      key: 'correoContacto',
    },
    {
      title: 'Razón Social',
      dataIndex: 'razonSocial',
      key: 'razonSocial',
    },
    {
      title: 'Giro Comercial',
      dataIndex: 'giroComercial',
      key: 'giroComercial',
    },
    {
      title: 'Dirección',
      dataIndex: 'direccionComercial',
      key: 'direccionComercial',
    },
    {
      title: 'Modificar',
      key: 'action',
      render: (record) => (
        <>
          <Button type="primary" onClick={() => showEditModal(record)}>
          <EditOutlined />
          </Button>
          <Popconfirm
        title="Confirmar elminiación."
        onConfirm={() => deleteRecord(record._id)}
        onCancel={() => console.log('User canceled delete')}
        okText="Si"
        cancelText="No"
      >
          <Button type="dashed" >
          <DeleteOutlined />
          </Button>
          </Popconfirm>
          
        </>
      ),
    },
  ];
  const showEditModal = (record) => {
    setCurrentRecord(record);
    setIsModalVisible(true);
  };

  const deleteRecord = async (id) => {
    await fetch(`${process.env.REACT_APP_NODE_END_POINT}/businesses/${id}`, {
      method: "DELETE",
    });
    const newRecords = records.filter((el) => el._id !== id);
    setRecords(newRecords);
  };

  const handleUpdate = (updatedRecord) => {
    setRecords(prevRecords =>
      prevRecords.map(rec => rec._id === updatedRecord._id ? updatedRecord : rec)
    );
  };

  const goToClientList = () => {
    history.push('/client-list'); // Navigate to /client-list
  };


  return (
    <>
        <div className="tabled">
            <Row gutter={[24, 0]}>
                <Col lg={24} md={24} sm={24} xs={24}>
                <Card 
    title={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>Lista de Empresas</span>
            <div> 
                <Button type="primary" onClick={goToClientList} style={{ marginLeft: '16px' }}>
                    Ver Personas
                </Button>
            </div>
        </div>
    }
>
    <div style={{ overflowX: 'auto' }}>
        <Table
            columns={columns}
            dataSource={records}
            rowKey={record => record._id}
            pagination={{
                showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} clientes`
            }}
        />
    </div>
</Card>
                </Col>
            </Row>
        </div>
        <Modal
            title="Editar Cliente"
            visible={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            footer={null}
        >
            <Edit 
                record={currentRecord} 
                handleUpdate={handleUpdate} 
                onClose={() => setIsModalVisible(false)} 
            />
        </Modal>
        <Modal
            title="Create New Client"
            visible={isNewClientModalVisible}
            onCancel={() => setIsNewClientModalVisible(false)}
            footer={null}
        >
            <BusinessForm handleCreate={handleCreate} onClose={() => setIsNewClientModalVisible(false)} />
        </Modal>
    </>
);
}

export default BusinessesList;