// client/components/ChartContainer.jsx
import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Typography, DatePicker, Col, Row } from "antd";
import dayjs from 'dayjs';

import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const { Title, Paragraph } = Typography;
const { RangePicker } = DatePicker;

const formatDate = (date) => {
  return `${date.year()}-${String(date.month() + 1).padStart(2, '0')}-${String(date.date()).padStart(2, '0')}`;
}

const EChart = () => {
  const [data, setData] = useState({ names: [], totals: [] });
  const [dateRange, setDateRange] = useState([dayjs().subtract(1, 'month'), dayjs()]);
  const [tempDateRange, setTempDateRange] = useState(); // New state for temporary date range

    
  useEffect(() => {
    const formattedStartDate = formatDate(dateRange[0]);
    const formattedEndDate = formatDate(dateRange[1]);
  
    const startDateISO = `${formattedStartDate}T00:00:00.000Z`;
    const endDateISO = `${formattedEndDate}T23:59:59.999Z`;
  
    const endpoint = `${process.env.REACT_APP_NODE_END_POINT}/graficos/aggregated-data?startDate=${startDateISO}&endDate=${endDateISO}`;
  
    console.log("Fetching from endpoint:", endpoint);
    
    fetch(endpoint)
      .then(response => {
          if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
      })
      .then(fetchedData => {
    
        // Sort the fetchedData by total in descending order and then slice to get top 10
        const sortedData = [...fetchedData].sort((a, b) => b.total - a.total).slice(0, 10);
        
        const names = sortedData.map(item => item._id);
        const totals = sortedData.map(item => item.total);
    
        setData({ names, totals });
        console.log("Received data:", fetchedData);
    })
    
      .catch(error => {
          console.error("Fetch error:", error);
      });
  }, [dateRange]);

  
  const onDateRangeChange = (dates) => {
    setTempDateRange(dates); // Set the temporary date range whenever a date is selected
  }

  const onDateRangeConfirm = () => {
    // Confirm the date range only when both dates are set
    if (tempDateRange && tempDateRange[0] && tempDateRange[1]) {
        setDateRange([dayjs(tempDateRange[0]).startOf('day'), dayjs(tempDateRange[1]).endOf('day')]);
    }
  }

  const chartOptions = {
      labels: data.names,
      responsive: [{
          breakpoint: 480,
          options: {
              chart: { width: 200 },
              legend: { position: 'bottom' }
          }
      }]
  };

  return (
    <>
    <div>
            <Row align="middle" gutter={16}>
                <Col>
                    <Title level={5}>Productos Vendidos</Title>
                    <Paragraph className="lastweek">Por Fechas</Paragraph>
                </Col>
                <Col flex="auto" style={{ textAlign: 'right' }}>
                    <RangePicker
                        value={tempDateRange || dateRange}
                        onChange={onDateRangeChange}
                        onOpenChange={onDateRangeConfirm} 
                        format="YYYY-MM-DD"
                    />
                </Col>
            </Row>
            <ReactApexChart
                options={chartOptions}
                series={data.totals}
                type="donut"
            />
        </div>
    </>
  );
};

export default EChart;