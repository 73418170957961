import React from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  TimePicker,
  Card,
  Row,
  Col,
} from "antd";
import InputMask from "react-input-mask";
import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import { updateFormData } from "../redux/multiStepFormSlice";

const { TextArea } = Input;
const { Option } = Select;

const FormModifiedDemo = () => {
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.multiStepForm.formData);
  const selectedOption = formData.selectedOption;
  const timeRange = formData.timeRange;

  const selectedValue = formData.selectedValue;
  const phoneNumber = formData.phoneNumber;
  const phoneWarning = formData.phoneWarning;
  const paymentMethod = formData.paymentMethod;

  const address = formData.address;
  const deliveryDate = formData.deliveryDate;
  const notes = formData.notes;

  const disabledTime = () => {
    return [
      ...Array.from({ length: 7 }, (_, i) => i),
      ...Array.from({ length: 2 }, (_, i) => i + 22),
    ];
  };

  const setMorningDeliveryTime = () => {
    const morningStartTime = dayjs().hour(10).minute(0).second(0).toISOString();
    const morningEndTime = dayjs().hour(14).minute(0).second(0).toISOString();
    const productionTime = dayjs().hour(8).minute(0).second(0).toISOString();
    dispatch(
      updateFormData({
        timeRange: [morningStartTime, morningEndTime],
        productionTime,
      })
    );
  };

  const setAfternoonDeliveryTime = () => {
    const afternoonStartTime = dayjs()
      .hour(14)
      .minute(0)
      .second(0)
      .toISOString();
    const afternoonEndTime = dayjs().hour(18).minute(0).second(0).toISOString();
    const productionTime = dayjs().hour(12).minute(0).second(0).toISOString();
    dispatch(
      updateFormData({
        timeRange: [afternoonStartTime, afternoonEndTime],
        productionTime,
      })
    );
  };

  const handleBlur = () => {
    if (formData.phoneNumber.length < 15) {
      dispatch(updateFormData({ phoneWarning: "No válido" }));
    } else {
      dispatch(updateFormData({ phoneWarning: null }));
    }
  };

  const handleDeliveryTimeChange = (value) => {
    // Ensure the selected time is interpreted as local time and converted to UTC for storage
    const deliveryTimeIso = value ? value.utc().toISOString() : null;

    // Calculate the production time by subtracting 2 hours from the delivery time
    let productionTimeIso = null;
    if (value) {
      const productionTime = value.subtract(2, "hour").utc();
      productionTimeIso = productionTime.toISOString();
    }

    // Dispatch the new times to the Redux store
    dispatch(
      updateFormData({
        deliveryTime: deliveryTimeIso,
        productionTime: productionTimeIso,
      })
    );
  };

  const sectorOptions = [
    "Viña del Mar",
    "Valparaiso",
    "Con Con",
    "Reñaca",
    "Quilpue",
    "Belloto",
    "Villa Alemana",
    "Peña Blanca",
    "Otro",
  ];

  return (
    <Card title="Datos de Entrega" style={{ width: "100%" }}>
      <Form layout="horizontal">
        <Row gutter={16}>
          <Col span={5}></Col>
          <Col span={14}>
            <Form.Item>
              <Button
                type={selectedOption === "Retiro" ? "primary" : "default"}
                onClick={() =>
                  dispatch(updateFormData({ selectedOption: "Retiro" }))
                }
              >
                Retiro
              </Button>
              <Button
                type={selectedOption === "Despacho" ? "primary" : "default"}
                onClick={() =>
                  dispatch(updateFormData({ selectedOption: "Despacho" }))
                }
              >
                Despacho
              </Button>
            </Form.Item>
          </Col>
        </Row>

        {selectedOption === "Despacho" && (
          <>
            <Row gutter={16}>
              <Col span={5}>
                <label>Dirección</label>
              </Col>
              <Col span={14}>
                <Form.Item>
                  <Input
                    type="text"
                    value={address}
                    onChange={(e) =>
                      dispatch(updateFormData({ address: e.target.value }))
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={5}>
                <label>Sector</label>
              </Col>
              <Col span={14}>
                <Form.Item>
                  <Select
                    value={selectedValue.sector} // Assuming `sector` is now under `selectedValue`
                    onChange={(value) => {
                      const index = sectorOptions.indexOf(value);
                      const price =
                        index < 6
                          ? 3500
                          : index === 6 || index === 7
                          ? 6500
                          : 0;

                      dispatch(
                        updateFormData({
                          selectedValue: {
                            ...selectedValue,
                            sector: value,
                            value: price,
                          },
                        })
                      );
                    }}
                  >
                    {sectorOptions.map((option, index) => (
                      <Option key={index} value={option}>
                        {option}
                      </Option>
                    ))}
                  </Select>

                  {selectedValue.value !== null && (
                    <div style={{ marginTop: 10 }}>
                      Precio: {selectedValue.value}
                    </div>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={5}>
                <label>Metodo de Pago</label>
              </Col>
              <Col span={14}>
                <Form.Item>
                  <Select
                    value={paymentMethod}
                    onChange={(value) =>
                      dispatch(updateFormData({ paymentMethod: value }))
                    }
                  >
                    <Option value="RedCompra">RedCompra</Option>
                    <Option value="Efectivo">Efectivo</Option>
                    <Option value="Transferencia">Transferencia</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </>
        )}

        {/* Fecha */}
        <Row gutter={16}>
          <Col span={5}>
            <label>Fecha</label>
          </Col>
          <Col span={14}>
            <Form.Item>
              <DatePicker
                value={deliveryDate ? dayjs(deliveryDate).local() : null}
                onChange={(date) => {
                  // Set the hour to 06:00 while maintaining the date
                  const withLocalTime = date
                    ? date.hour(6).minute(0).second(0)
                    : null;

                  const isoDateWithLocalTime = withLocalTime
                    ? withLocalTime.toISOString()
                    : null;

                  dispatch(
                    updateFormData({ deliveryDate: isoDateWithLocalTime })
                  );
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        {/* Time Range Picker for Despacho */}
        {selectedOption === "Despacho" && (
          <>
            <Row gutter={16}>
              <Col span={14} offset={5}>
                <Form.Item>
                  <Button type="primary" onClick={setMorningDeliveryTime}>
                    Mañana
                  </Button>
                  <Button type="primary" onClick={setAfternoonDeliveryTime}>
                    Tarde
                  </Button>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={5}>
                <label>Rango </label>
              </Col>
              <Col span={14}>
                <Form.Item>
                <TimePicker.RangePicker
  format="HH:mm"
  value={timeRange?.map(t => dayjs(t).utc().local()) || []}
  onChange={(newRange) => {
    let isoRange;
    if (newRange && newRange.length === 2) {
      // Both times are selected
      isoRange = newRange.map(t => t.utc().toISOString());
    } else {
      // The range is cleared
      isoRange = [];
    }
    dispatch(updateFormData({ timeRange: isoRange }));
  }}
/>

                </Form.Item>
              </Col>
            </Row>
          </>
        )}

        {/* Hora Entrega */}

        <Row gutter={16}>
          <Col span={5}>
            <label>Hora Entrega</label>
          </Col>
          <Col span={14}>
            <Form.Item>
              <TimePicker
                format="HH:mm"
                value={
                  formData.deliveryTime
                    ? dayjs(formData.deliveryTime).utc().local()
                    : null
                }
                onChange={handleDeliveryTimeChange}
                minuteStep={15}
                hideDisabledOptions
                disabledTime={disabledTime}
              />
            </Form.Item>
          </Col>
        </Row>

        {/* Hora Producción */}
        <Row gutter={16}>
          <Col span={5}>
            <label>Hora Producción</label>
          </Col>
          <Col span={14}>
            <Form.Item>
              <TimePicker
                format="HH:mm"
                value={
                  formData.productionTime
                    ? dayjs(formData.productionTime).utc().local()
                    : null
                }
                onChange={(value) => {
                  const isoTime = value ? value.utc().toISOString() : null;
                  dispatch(updateFormData({ productionTime: isoTime }));
                }}
                minuteStep={15}
                hideDisabledOptions
                disabledTime={disabledTime}
                allowClear={true}
              />
            </Form.Item>
          </Col>
        </Row>

        {/* Teléfono */}
        <Row gutter={16}>
          <Col span={5}>
            <label>Teléfono</label>
          </Col>
          <Col span={14}>
            <Form.Item>
              <InputMask
                mask="+56 9 9999 9999"
                maskChar={null}
                value={phoneNumber}
                onChange={(e) =>
                  dispatch(updateFormData({ phoneNumber: e.target.value }))
                }
                onBlur={handleBlur}
              >
                {(inputProps) => <Input {...inputProps} type="tel" />}
              </InputMask>
              {phoneWarning && <div>{phoneWarning}</div>}
            </Form.Item>
          </Col>
        </Row>

        {/* Notas */}
        <Row gutter={16}>
          <Col span={5}>
            <label>Notas</label>
          </Col>
          <Col span={14}>
            <Form.Item>
              <TextArea
                rows={4}
                value={notes}
                onChange={(e) =>
                  dispatch(updateFormData({ notes: e.target.value }))
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={5}></Col>
          <Col span={14}></Col>
        </Row>
      </Form>
    </Card>
  );
};

export default FormModifiedDemo;
