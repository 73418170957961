import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button, Table, Select, Popconfirm, Form, Input, Card, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const { Option } = Select;

const Usuarios = () => {
  const [users, setUsers] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_NODE_END_POINT}/api/auth/users`);
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleRoleChange = async (userId, newRole) => {
    try {
        const response = await axios.patch(`${process.env.REACT_APP_NODE_END_POINT}/api/auth/users/${userId}`, {
            role: newRole
        });
        if (response.status === 200) {
            fetchUsers(); // Refresh the user list after a successful role update
        }
    } catch (error) {
        console.error('There was an error updating the role:', error);
    }
};


  const handleDeleteUser = async (userId) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_NODE_END_POINT}/api/auth/users/${userId}`);
      if (response.status === 200) {
        message.success('Usuario Borrado');  
        fetchUsers();
      }
    } catch (error) {
      console.error('There was an error deleting the user:', error);
      message.error('There was an error deleting the user'); 
    }
};


  const handleAddUser = async (values) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_NODE_END_POINT}/api/auth/users/add`, values);
      if (response.status === 200) {
        message.success('Usuario Agregado'); 
        setIsModalVisible(false);
        fetchUsers();
      }
    } catch (error) {
      console.error('There was an error adding the user', error);
      message.error('Error agregando usuario'); 
    }
  };

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Usuario',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Rol',
      dataIndex: 'role',
      key: 'role',
      render: (role, record) => (
        <div>
          <Select 
            defaultValue={role}
            style={{ width: 120 }}
            onChange={value => {
              if (value !== role) {
                // Trigger Modal.confirm upon selecting a new option
                Modal.confirm({
                  title: 'Seguro de cambiar el rol?',
                  onOk() {
                    handleRoleChange(record._id, value);
                  }
                });
              }
            }}
          >
            <Option value="admin">Admin</Option>
            <Option value="worker">Trabajador</Option>
            <Option value="delivery">Despacho</Option>
          </Select>
        </div>
      ),
    },
    
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <Popconfirm title="Confirmar borrar cliente." onConfirm={() => handleDeleteUser(record._id)}>
        <DeleteOutlined style={{ color: 'red', fontSize: '18px' }} />
    </Popconfirm>
    
      ),
    },
    
  ];

  return (
    <Card>
    <div>
      <Button type="primary" onClick={() => setIsModalVisible(true)}>
        Agregar Usuario
      </Button>

      <Table dataSource={users} columns={columns} rowKey="_id" />

      <Modal
        title=""
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              form.resetFields();
              handleAddUser(values);
            })
            .catch(info => {
              console.log('Validation failed:', info);
            });
        }}
      >
        <Form form={form} layout="vertical" name="userForm">
          <Form.Item
            name="name"
            label="Nombre"
            rules={[{ required: true, message: 'Ingresar Nombre!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="username"
            label="Usuario"
            rules={[{ required: true, message: 'Ingresar Nombre de Usuario!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="password"
            label="Clave"
            rules={[{ required: true, message: 'Ingresar Clave!' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="role"
            label="Rol"
            rules={[{ required: true, message: 'Ingresar Rol!' }]}
          >
            <Select placeholder="Seleccionar Rol">
              <Option value="admin">Admin</Option>
              <Option value="worker">Trabajador</Option>
              <Option value="delivery">Despacho</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
    </Card>
  );
};

export default Usuarios;
