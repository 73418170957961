import React, { useEffect, useRef, useState } from "react";
import { Steps, Button, Card, Row, Col, message } from "antd";
import CreateAdapted from "./pasoUno";
import ProductTable from "./pasoDos";
import FormModifiedDemo from "./pasoTres";
import BusinessForm from "../clientes/businessForm";
import { useDispatch, useSelector } from 'react-redux';
import { updateFormData, updateStep, resetFormData, fetchOrders } from '../redux/multiStepFormSlice'

const steps = [
  {
    title: "Cliente",
    content: "Contenido paso 1.",
  },
  {
    title: "Productos",
    content: "Contenido paso 2.",
  },
  {
    title: "Entrega",
    content: "Contenido paso 3.",
  },
];

let resizeTimeout;

const FormSteps = () => {
  const dispatch = useDispatch();
  const formData = useSelector(state => state.multiStepForm.formData);
  const currentStep = useSelector(state => state.multiStepForm.step);
  const [isBusiness, setIsBusiness] = useState(false); 
  const elementRef = useRef(null);
  const [lastSubmission, setLastSubmission] = useState(null);

  useEffect(() => {
    const observerCallback = (entries) => {
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }

        if (resizeTimeout) {
          clearTimeout(resizeTimeout);
        }

        resizeTimeout = setTimeout(() => {
          console.log("Resize handler"); // Replace with your actual resize handling code
        }, 100);
      });
    };

    const resizeObserver = new ResizeObserver(observerCallback);

    if (elementRef.current) {
      resizeObserver.observe(elementRef.current); // Observing the element
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const updateTableData = (newTableData) => {
    dispatch(updateFormData({ tableData: newTableData }));
  };

  const goToNextStep = () => {
    dispatch(updateStep(currentStep + 1));
  };

  const goToPreviousStep = () => {
    dispatch(updateStep(currentStep - 1));
  };

  const toggleForm = () => {
    setIsBusiness(!isBusiness);
  };

  const handleSubmit = () => {
    const { businessData, ...otherFormData } = formData;
    const dataToSend = { step: currentStep, formData: { ...otherFormData, businessData } };
    
    const currentSubmission = JSON.stringify(dataToSend);
    if (currentSubmission === lastSubmission) {
      console.warn('Duplicate submission detected');
      return;
    }

    setLastSubmission(currentSubmission);

    const url = `${process.env.REACT_APP_NODE_END_POINT}/ordenes`;
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataToSend),
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Failed to create order');
      }
    })
    .then((data) => {
      message.success('Orden creada');
      dispatch(updateStep(0));
      dispatch(resetFormData());   // Resetting form data in Redux store
      dispatch(fetchOrders());
      setIsBusiness(false);   // Resetting back to regular client form
    })
    .catch((error) => {
      console.error('Error:', error);
      message.error('Failed to create order');
    });
  };
  

  return (
    <div style={{ padding: "24px" }} ref={elementRef}>
      <Card>
        <Steps current={currentStep}>
          {steps.map((step, index) => (
            <Steps.Step key={index} title={step.title} />
          ))}
        </Steps>
        <div style={{ marginTop: "24px" }}>
          {currentStep === 0 && (
            <div>
              <Row justify="end">
                <Col>
                  <Button onClick={toggleForm}>
                    {isBusiness ? "Ir a Personas" : "Ir a Empresas"}
                  </Button>
                </Col>
              </Row>
              {isBusiness ? (
                <BusinessForm
                  next={goToNextStep}
                  dispatch={dispatch}
                  formData={formData}
                />
              ) : (
                <CreateAdapted
                  next={goToNextStep}
                  dispatch={dispatch}
                  formData={formData}
                />
              )}
            </div>
          )}

{currentStep === 1 && (
          <ProductTable 
          prev={goToPreviousStep} 
          next={goToNextStep} 
          updateTableData={updateTableData} 
          tableData={formData.tableData} 
          formData={formData} 
          dispatch={dispatch}
        />
        )}

        {currentStep === 2 && (
          <FormModifiedDemo
            prev={goToPreviousStep}
            submit={handleSubmit}
            dispatch={dispatch}
            formData={formData}
          />
        )}
      </div>
        <div style={{ marginTop: "24px" }}>
          
          {currentStep > 0 && (
            <Button
              type="primary"
              onClick={goToPreviousStep}
              style={{ marginRight: "8px" }}
            >
              Volver
            </Button>
          )}
          {currentStep < steps.length - 1 && (
            <Button type="primary" onClick={() => goToNextStep()}>
              Siguiente
            </Button>
          )}
          {currentStep === steps.length - 1 && (
            <Button type="primary" onClick={handleSubmit}>
              Agregar Orden
            </Button>
          )}
        </div>
      </Card>
    </div>
  );
};

export default FormSteps;
