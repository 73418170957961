import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Form, Input, Row, Col, Select } from 'antd';

const { Option } = Select;

export default function Productos({ closeNewProductModal }) {
  const [variants, setVariants] = useState([{name: "", price: ""}]);
  const [form] = Form.useForm();
  const history = useHistory();

  const formItemLayout = { labelCol: { span: 8 }, wrapperCol: { span: 14 } };
  const buttonItemLayout = { wrapperCol: { span: 14, offset: 8 } };

  const removeVariant = (indexToRemove) => {
    setVariants(prev => prev.filter((_, index) => index !== indexToRemove));
  };

  const addVariant = () => {
    setVariants(prev => [...prev, {name: "", price: ""}]);
  };

  const handleVariantChange = (index, field, value) => {
    const newVariants = [...variants];
    newVariants[index][field] = value;
    setVariants(newVariants);
  };

  const onSubmit = async (values) => {
    try {
      values.variants = variants;
      await fetch(`${process.env.REACT_APP_NODE_END_POINT}/productos`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
      form.resetFields();
      setVariants([{name: "", price: ""}]);
      
      if (typeof closeNewProductModal === 'function') {
        closeNewProductModal();  // Close the modal and refresh the list
      }
    } catch (error) {
      window.alert(error);
    }
  };
  
  

  return (
    <div>
      <Row>
        <Col span={4}></Col>
        <Col span={14}>
          <h3>Crear Nuevo Producto</h3>
        </Col>
      </Row>
      <Form {...formItemLayout} form={form} layout="horizontal" onFinish={onSubmit} style={{ maxWidth: 600 }}>
        
        <Form.Item label="Nombre:" name="name" rules={[{ required: true, message: 'Por favor, ingrese el nombre.' }]}>
          <Input placeholder="Nombre del producto" />
        </Form.Item>

        <Form.Item label="Etiqueta:" name="label" rules={[{ required: true, message: 'Por favor, seleccione una categoría.' }]}>
          <Select placeholder="Seleccione una categoría">
            <Option value="Cocina">Cocina</Option>
            <Option value="Masas">Masas</Option>
            <Option value="Canapés">Canapes</Option>
            <Option value="Pastelería">Pasteleria</Option>
            <Option value="Bebestibles">Bebestibles</Option>
            <Option value="Otros">Otros</Option>
          </Select>
        </Form.Item>

        <Form.Item label="Variantes:" name="variants">
        {variants.map((variant, index) => (
          <div key={index} style={{ marginBottom: 8, display: 'flex', alignItems: 'center' }}>
            <Input 
              value={variant.name}
              placeholder={`Variante ${index + 1}`}
              onChange={(e) => handleVariantChange(index, 'name', e.target.value)}
              style={{ marginRight: 8 }}
            />
            <Input 
              value={variant.price}
              placeholder={`Precio ${index + 1}`}
              onChange={(e) => handleVariantChange(index, 'price', e.target.value)}
              style={{ marginRight: 8 }}
            />
            <Button onClick={() => removeVariant(index)} type="dashed" danger>
              Borrar
            </Button>
          </div>
        ))}
        <Button type="dashed" onClick={addVariant}>Agregar variante</Button>
      </Form.Item>

        <Form.Item {...buttonItemLayout}>
          <Button type="primary" htmlType="submit">Crear Producto</Button>
        </Form.Item>
      </Form>
    </div>
  );
}
