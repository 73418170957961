import React, { useState, useEffect } from 'react';
import { Card, Button, Spin } from 'antd';
import axios from 'axios';

const WooCommerceOrders = () => {
    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(false); 

    const fetchOrders = async () => {
        setIsLoading(true);  // Start loading
        try {
            const response = await axios.get(`${process.env.REACT_APP_NODE_END_POINT}/api/woocommerce/orders`);
            setOrders(response.data);
        } catch (error) {
            console.error("Failed to fetch orders from backend:", error);
        } finally {
            setIsLoading(false);  // End loading
        }
    };

        useEffect(() => {
            fetchOrders();
        }, []);

    const handleRefresh = () => {
        fetchOrders();
    };
    
    return (
        <div>
<Card>
                <Button type="primary" onClick={handleRefresh} style={{ marginBottom: 20 }}>Recargar</Button>
                <h2>Pedidos Web</h2>
                {isLoading ? (
                    <Spin size="large" style={{ display: 'block', textAlign: 'center', marginTop: 50 }} />
                ) : (
                    orders.map(order => {
                    // Extract the desired meta_data values
                    const findMetaValue = key => {
                        const metaItem = order.meta_data.find(meta => meta.key === key);
                        return metaItem?.value || ""; // Provide a default value of "NULL"
                    };
                    
                    const shippingMethod = findMetaValue("_shipping_wooccm10");
                    const fullName = findMetaValue("_shipping_wooccm14");
                    const rut = findMetaValue("_billing_wooccm11");
                    const phone = findMetaValue("_shipping_wooccm13");
                    const address = order.shipping.address_1 || "";
                    const sector = order.shipping.city || "";
                    const deliveryTime = findMetaValue("_shipping_wooccm11");
                    const deliveryDate = findMetaValue("_shipping_wooccm12");
                    const pickupName = findMetaValue("_shipping_wooccm16");
                    const pickupTime = findMetaValue("_shipping_wooccm17");
                    const pickupDate = findMetaValue("_shipping_wooccm18");
                    const status = order.status || "";
    
                    return (
                        <Card key={order.id} style={{ marginBottom: 20 }}>
                            <h3>Orden #{order.number}</h3>
                            <p>Status: {status}</p>
                            
                            <p>Rut: {rut}</p>
                            <p>Nombre Despacho: {fullName}</p>
                            <p>Teléfono: {phone}</p>
                            <p>Dirección: {address}</p>
                            <p>Sector: {sector}</p>
                            <p>Metodo Entrega: {shippingMethod}</p>
                            <p>Horario Despacho: {deliveryTime}</p>
                            <p>Día Despacho: {deliveryDate}</p>
                            <p>Nombre Entrega: {pickupName}</p>
                            <p>Horario Retiro: {pickupTime}</p>
                            <p>Día Retiro: {pickupDate}</p>
    
                            <ul>
                                Productos:
                                {order.line_items.map(item => {
                                    const itemName = item.name || "NULL";
                                    const itemQty = item.quantity || "NULL";
                                    const itemTotal = item.total || "NULL";
                                    const currency = order.currency || "NULL";
    
                                    return (
                                        <li key={item.id}>
                                            {itemName} (Cantidad: {itemQty}) - {itemTotal} {currency}
                                        </li>
                                    );
                                })}
                            </ul>
                            </Card>
                                        );
                                    })
                                )}
                            </Card>
                        </div>
                    );
                };
                

export default WooCommerceOrders;
