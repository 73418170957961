import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  users: [],
  currentUser: null, 
  currentUserRole: null, 
  authStatus: 'loading', 
  authError: null,
};

// Fetch users
export const fetchUsers = createAsyncThunk(
  'userManagement/fetchUsers',
  async () => {
    const response = await axios.get(`${process.env.REACT_APP_NODE_END_POINT}/api/users`);
    return response.data;
  }
);

// Create user
export const createUser = createAsyncThunk(
  'userManagement/createUser',
  async (userData) => {
    const response = await axios.post(`${process.env.REACT_APP_NODE_END_POINT}/api/users`, userData);
    return response.data;
  }
);

// User login
export const loginUser = createAsyncThunk(
  'userManagement/loginUser',
  async (credentials) => {
    const response = await axios.post(`${process.env.REACT_APP_NODE_END_POINT}/api/auth/login`, credentials, {
      withCredentials: true,
    });
    return response.data;
  }
);


// Verify user token
export const verifyUserToken = createAsyncThunk(
  'userManagement/verifyUserToken',
  async () => {
    const response = await axios.post(`${process.env.REACT_APP_NODE_END_POINT}/api/auth/verify`, {}, { withCredentials: true });  // <-- Added { withCredentials: true }
    return response.data;
  }
);


// Logout user
export const logoutUser = createAsyncThunk(
  'userManagement/logoutUser',
  async () => {
   
    await axios.get(`${process.env.REACT_APP_NODE_END_POINT}/api/auth/logout`);
  }
);

const userSlice = createSlice({
  name: 'userManagement',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.users = action.payload;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.users.push(action.payload);
      })
      .addCase(loginUser.pending, (state) => {
        state.authStatus = 'loading';
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.currentUser = action.payload.username;
        state.currentUserRole = action.payload.role; // This should set the role correctly now
        state.authStatus = 'authenticated';
        state.authError = null;
    })
    
      .addCase(loginUser.rejected, (state, action) => {
        state.authStatus = 'failed';
        state.authError = action.error.message || 'Failed to login';
      })
      .addCase(verifyUserToken.fulfilled, (state, action) => {
        if (action.payload.status) {
            state.currentUser = action.payload.user;
            state.currentUserRole = action.payload.role; // <-- Set the role from the response
            state.authStatus = 'authenticated';
        } else {
            state.currentUser = null;
            state.currentUserRole = null; 
            state.authStatus = 'idle';
        }
    })
    .addCase(verifyUserToken.rejected, (state) => {
      state.authStatus = 'failed'; // or 'unauthenticated'
      state.currentUser = null;
      state.currentUserRole = null;
  })
      .addCase(logoutUser.fulfilled, (state) => {
        state.currentUser = null;
        state.currentUserRole = null;
        state.authStatus = 'idle';
      });
  },
});

export default userSlice.reducer;