import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import '../../assets/styles/main.css';
import { Button, Form, Input, Row, Col } from 'antd';
import { format } from 'rut.js'
//CREAR CLIENTES//

export default function Create() {
  const [form] = Form.useForm();
  const [clientData, setClientData] = useState(null);
  const [businessFound, setBusinessFound] = useState(false);
  const history = useHistory();

  const formItemLayout = { labelCol: { span: 8 }, wrapperCol: { span: 14 } };
  const buttonItemLayout = { wrapperCol: { span: 14, offset: 8 } };

  const searchClient = async (rut) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_NODE_END_POINT}/record?rut=${rut}`);
      const data = await response.json();
      if (Array.isArray(data) && data.length > 0 && data[0].Nombre) {
        setClientData(data[0]);
      } else {
        setClientData(null);
      }
    } catch (error) {
      console.error("Failed to search client:", error);
    }
  };

  const searchBusiness = async (rut) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_NODE_END_POINT}/businesses?rutEmpresa=${rut}`);
      const data = await response.json();
      if (Array.isArray(data) && data.length > 0) {
        setBusinessFound(true);
      } else {
        setBusinessFound(false);
      }
    } catch (error) {
      console.error("Failed to search business:", error);
    }
  };

  const handleRUTChange = (e) => {
    let rutValue = e.target.value;
    
    if (!rutValue || rutValue === "-") {
        form.resetFields(); // This will reset all the fields in the form
        setClientData(null);
        setBusinessFound(false);
        return;
    }

    rutValue = format(rutValue);
    form.setFieldsValue({ rut: rutValue });

    if (rutValue.length >= 11) { // check for 11 characters
        searchClient(rutValue);
        searchBusiness(rutValue);
    }
};
  const useClientData = () => {
    form.setFieldsValue(clientData);
  };

  const goToBusinessForm = () => {
    history.push("/business-form");
  };

  const onSubmit = async (values) => {
    try {
      await fetch("${process.env.REACT_APP_NODE_END_POINT}/record", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
      form.resetFields();
      history.push("/");
    } catch (error) {
      window.alert(error);
    }
  };

  return (
    <div>
      <Row>
        <Col span={8}></Col> {/* Empty column to push next column to the right */}
        <Col span={10}>
          <h3>Seleccionar Cliente</h3>
        </Col>
      </Row>
      <Row>
        <Col span={8}></Col> 
        <Col span={10}>  
      <Form
        {...formItemLayout}
        layout="horizontal"
        form={form}
        onFinish={onSubmit}
        style={{ maxWidth: 600 }}
      >
        <Form.Item 
            label="RUT" 
            name="rut"
            rules={[{ required: true, message: 'Por favor, ingrese su RUT.' }]}
        >
            <Input placeholder="Insertar RUT" onChange={handleRUTChange}  maxLength={12}/>
            
        </Form.Item>

        {clientData && !businessFound && (
          <Form.Item {...buttonItemLayout}>
              <Button onClick={useClientData}>Cliente: {clientData.Nombre}</Button>
          </Form.Item>
        )}

        {businessFound && (
          <Form.Item {...buttonItemLayout}>
              <Button onClick={goToBusinessForm}>Ir a empresas</Button>
          </Form.Item>
        )}

        <Form.Item 
            label="Nombre" 
            name="Nombre"
            rules={[{ required: true, message: 'Por favor, ingrese su nombre.' }]}
        >
            <Input placeholder="Insertar Nombre" />
        </Form.Item>

        <Form.Item 
            label="Número" 
            name="Numero"
            rules={[{ required: true, message: 'Por favor, ingrese su número.' }]}
        >
            <Input placeholder="Insertar Número" />
        </Form.Item>

        <Form.Item 
            label="Email" 
            name="email"
            rules={[{ required: true, message: 'Por favor, ingrese su email.' }]}
        >
            <Input type="email" placeholder="Insertar Email" />
        </Form.Item>

      

   
        <Col>
            <Button type="primary" htmlType="submit">Crear Cliente</Button>
        </Col>
    
    

</Form>
        </Col>
      </Row>
    </div>
  );
}