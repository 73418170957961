import React, { useEffect } from 'react';
import { Form, Input, Button } from 'antd';

// EDITAR CLIENTES
function Edit({ record, handleUpdate, closeModal, onClose }) {
  const [form] = Form.useForm();
  const id = record?._id;

  useEffect(() => {
    if (!id) return;

    async function fetchData() {
      try {
        const response = await fetch(`${process.env.REACT_APP_NODE_END_POINT}/record/${id}`);

        if (!response.ok) {
          const message = `An error has occurred: ${response.statusText}`;
          window.alert(message);
          return;
        }

        const recordData = await response.json();
        if (recordData && recordData.error) {
          window.alert(`Record with id ${id} not found`);
          closeModal();
          return;
        }

        form.setFieldsValue({
          Nombre: recordData.Nombre,
          Numero: recordData.Numero,
          email: recordData.email,
          rut: recordData.rut,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
        window.alert("An unexpected error occurred. Please try again.");
      }
    }

    fetchData();
  }, [id, closeModal, form]);

  async function onSubmit(values) {
    const editedPerson = {
      _id: id,
      ...values
    };
    
    const response = await fetch(`${process.env.REACT_APP_NODE_END_POINT}/record/${id}`, {
      method: "PATCH",
      body: JSON.stringify(editedPerson),
      headers: {
        'Content-Type': 'application/json'
      },
    });

    if (response.ok) {
      if (typeof handleUpdate === 'function') {
        handleUpdate(editedPerson);
      }
    }
    onClose(); // Close the modal
  }

  const formItemLayout = { labelCol: { span: 4 }, wrapperCol: { span: 14 } };
  const buttonItemLayout = { wrapperCol: { span: 14, offset: 4 } };

  return (
    <Form
      {...formItemLayout}
      layout="horizontal"
      form={form}
      initialValues={{
        Nombre: record ? record.Nombre : "",
        Numero: record ? record.Numero : "",
        email: record ? record.email : "",
        rut: record ? record.rut : ""
      }}
      onFinish={onSubmit}
      style={{ maxWidth: 600 }}
    >
      <Form.Item label="Nombre" name="Nombre" rules={[{ required: true, message: 'Please input Nombre!' }]}>
        <Input />
      </Form.Item>
      <Form.Item label="Número" name="Numero" rules={[{ required: true, message: 'Please input Numero!' }]}>
        <Input />
      </Form.Item>
      <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Please input a valid email!', type: 'email' }]}>
        <Input />
      </Form.Item>
      <Form.Item label="RUT" name="rut" rules={[{ required: true, message: 'Please input RUT!' }]}>
        <Input />
      </Form.Item>
      <Form.Item {...buttonItemLayout}>
        <Button type="primary" htmlType="submit">
          Actualizar cliente
        </Button>
      </Form.Item>
    </Form>
  );
}

export default Edit;
