import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, Form, Input, Row, Col, Select } from 'antd';

const { Option } = Select;

const EditProduct = ({ id, closeModal, refetch }) => {
  const [variants, setVariants] = useState([{name: "", price: ""}]);
  const [form] = Form.useForm();

  const formItemLayout = { labelCol: { span: 8 }, wrapperCol: { span: 14 } };
  const buttonItemLayout = { wrapperCol: { span: 14, offset: 8 } };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_NODE_END_POINT}/productos/${id}`);
        const product = await response.json();
        form.setFieldsValue(product);
        setVariants(product.variants || [{name: "", price: ""}]);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id, form]);

  const removeVariant = (indexToRemove) => {
    setVariants(prev => prev.filter((_, index) => index !== indexToRemove));
  };

  const addVariant = () => {
    setVariants(prev => [...prev, {name: "", price: ""}]);
  };

  const handleVariantChange = (index, field, value) => {
    const newVariants = [...variants];
    newVariants[index][field] = value;
    setVariants(newVariants);
  };

  const onSubmit = async (values) => {
    try {
      values.variants = variants;
      await fetch(`${process.env.REACT_APP_NODE_END_POINT}/productos/${id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
      if (typeof refetch === 'function') {
        refetch();
      }
      if (typeof closeModal === 'function') {
        closeModal();
      }
    } catch (error) {
      console.error('Error updating product:', error);
    }
  };
  

  return (
    <div>
      <Row>
        <Col span={4}></Col>
        <Col span={14}>
          <h3>Actualizar Producto</h3>
        </Col>
      </Row>
      <Form {...formItemLayout} form={form} layout="horizontal" onFinish={onSubmit} style={{ maxWidth: 600 }}>
        
        <Form.Item label="Nombre:" name="name" rules={[{ required: true, message: 'Por favor, ingrese el nombre.' }]}>
          <Input placeholder="Nombre del producto" />
        </Form.Item>

        <Form.Item label="Etiqueta:" name="label" rules={[{ required: true, message: 'Por favor, seleccione una categoría.' }]}>
          <Select placeholder="Seleccione una categoría">
            {/* Add your options here */}
          </Select>
        </Form.Item>

        <Form.Item label="Variantes:" name="variants">
          {variants.map((variant, index) => (
            <div key={index} style={{ marginBottom: 8, display: 'flex', alignItems: 'center' }}>
              <Input 
                value={variant.name}
                placeholder={`Variante ${index + 1}`}
                onChange={(e) => handleVariantChange(index, 'name', e.target.value)}
                style={{ marginRight: 8 }}
              />
              <Input 
                value={variant.price}
                placeholder={`Precio ${index + 1}`}
                onChange={(e) => handleVariantChange(index, 'price', e.target.value)}
                style={{ marginRight: 8 }}
              />
              <Button onClick={() => removeVariant(index)} type="dashed" danger>
                Borrar
              </Button>
            </div>
          ))}
          <Button type="dashed" onClick={addVariant}>Agregar variante</Button>
        </Form.Item>

        <Form.Item {...buttonItemLayout}>
          <Button type="primary" htmlType="submit">Actualizar Producto</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditProduct;
