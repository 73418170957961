import React, { useState, useEffect } from "react";
import { addProduct } from "../redux/multiStepFormSlice"
import { useDispatch } from 'react-redux';
import { AutoComplete, Select, InputNumber, Button, Form, Input, Row, Col, Card,  } from "antd";

export const ProductFetcher = ({ orderId }) => {
  const [options, setOptions] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [form] = Form.useForm();
  const [finalPrice, setFinalPrice] = useState(0);
  const [tableData] = useState([]);
  const dispatch = useDispatch();

  const label = selectedProduct ? selectedProduct.label : 'No Label';
  

  const fetchProductsByName = async (name) => {
    if (!name) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_NODE_END_POINT}/productos?name=${name}`
      );
      const data = await response.json();
      const filteredData = data.filter((item) =>
        item.name.toLowerCase().startsWith(name.toLowerCase())
      );
      setOptions(
        filteredData.map((item) => ({ value: item.name, data: item }))
      );
    } catch (error) {
      console.error("Failed to search product:", error);
    }
  };

  const handleNewNameChange = async (value, isSelect) => {
    if (isSelect) {
      const product = options.find((option) => option.value === value);
      setSelectedProduct(product.data);
    } else {
      await fetchProductsByName(value);
    }
  };

  const handleNewVariant = (variantName) => {
    if (selectedProduct) {
      const variant = selectedProduct.variants.find(
        (v) => v.name === variantName
      );
      setSelectedVariant(variant);
    }
  };

  useEffect(() => {
    // Set selectedVariant to the first variant when selectedProduct changes
    if (selectedProduct && selectedProduct.variants && selectedProduct.variants.length > 0) {
      setSelectedVariant(selectedProduct.variants[0]);
      form.setFieldsValue({ variantes: selectedProduct.variants[0].name });  // set the initial form value
    }
  }, [selectedProduct, form]);
  
  useEffect(() => {
    if (selectedVariant && quantity) {
      setFinalPrice(selectedVariant.price * quantity);
    }
  }, [selectedVariant, quantity]);

  const AddNewProduct = () => {
    const formData = form.getFieldsValue();

    const newRow = {
      key: tableData.length.toString(),
      name: formData.name,
      variant: formData.variantes,
      quantity: formData.cantidad,
      price: finalPrice / formData.cantidad,
      individualPrice: selectedVariant ? selectedVariant.price : 0,
      subtotal: finalPrice,
      notas: formData.comment,
      state: false,
      approval: false,
      label: label,
    };

    // Reset form fields
    form.resetFields();
    setSelectedProduct(null);
    setSelectedVariant(null);
    setQuantity(1);
    setFinalPrice(0);

    dispatch(addProduct({ newRow, id: orderId }));

  };

  return (
    <Card>
   
          <Form
            form={form}
            layout="vertical"
            style={{ width: "100%" }}
            initialValues={{ cantidad: 1 }}
          >
            <Form.Item
              label="Producto"
              name="name"
              style={{ marginBottom: "4px" }}
            >
              <AutoComplete
                options={options}
                placeholder="Insertar nombre del producto"
                onSelect={(value) => handleNewNameChange(value, true)}
                onSearch={(value) => handleNewNameChange(value, false)}
                filterOption={false}
              />
            </Form.Item>

            <Form.Item
              label="Variante"
              name="variantes"
              style={{ marginBottom: "4px" }}
            >
              <Select
                disabled={!selectedProduct}
                onChange={handleNewVariant}
              >
                {selectedProduct &&
                  selectedProduct.variants.map((variant, index) => (
                    <Select.Option key={index} value={variant.name}>
                      {variant.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>

            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  label="Cantidad"
                  name="cantidad"
                  style={{ marginBottom: "4px" }}
                >
                  <InputNumber
                    min={1}
                    initialvalues={1}
                    onChange={(val) => setQuantity(val)}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Sub Total" style={{ marginBottom: "4px" }}>
                  <span>{finalPrice > 0 ? finalPrice : "-"}</span>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label="Notas"
              name="comment"
              style={{ marginBottom: "4px" }}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" onClick={AddNewProduct}>
                Agregar
              </Button>
            </Form.Item>
         
          </Form>
      
    </Card>
  );
};

export default ProductFetcher;

