import { createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import dayjs from 'dayjs';
// Initial state of the form and business data
const initialState = {
  step: 0,
  formData: {
    rut: '',
    Nombre: '',
    Numero: '',
    email: null,
    tableData: [],
    _id: '',
    productionTime: dayjs("1970-01-01T00:00:00").toISOString(),
    deliveryTime: dayjs("1970-01-01T00:00:00").toISOString(),
    selectedOption: 'Retiro',
    paymentMethod: null,
    phoneNumber: '+56',
    notes: '',
    selectedValue: { sector: '', value: '0', key: null },
    timeRange: [
      dayjs("1970-01-01T00:00:00").toISOString(),
      dayjs("1970-01-01T00:00:00").toISOString(),
  ],
    address: '',
    deliveryDate: dayjs().toISOString(),
    businessData: '',
  },
  allOrders: [],
  labelsData: [], 
  users: [],
 
};


export const deleteOrderAction = (id) => {
  return async (dispatch) => {
    try {
      await axios.delete(`${process.env.REACT_APP_NODE_END_POINT}/ordenes/borrar/${id}`);
      dispatch({ type: 'DELETE_ORDER', payload: id });
    } catch (error) {
      console.error("Failed to delete order:", error);
    }
  };
};



export const adjustTime = createAsyncThunk(
  'multiStepForm/adjustTime',
  async (payload, { rejectWithValue }) => {
    console.log("Received payload in adjustTime:", payload);

    const { id, newProductionTime, newDeliveryTime, newTimeRange } = payload;
    try {
        // Prepare the data to be sent, including the 'fromFrontend' flag
        const data = {
            productionTime: newProductionTime || null,
            deliveryTime: newDeliveryTime || null,
            timeRange: newTimeRange || [],
            fromFrontend: true // Indicate that the request is coming from the frontend
        };

        // Send the PATCH request to the backend
        const response = await axios.patch(`${process.env.REACT_APP_NODE_END_POINT}/ordenes/updateTimes/${id}`, data);
        console.log("Data sent to backend:", data);

        // Handle the response
        if (response.status === 200) {
            return payload;
        } else {
            return rejectWithValue('Failed to adjust time');
        }
    } catch (error) {
        console.error('Could not adjust time:', error);
        return rejectWithValue(error.message);
    }
  }
);


export const syncOrderData = createAsyncThunk( //agregar productos
  'multiStepForm/syncOrderData',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_NODE_END_POINT}/ordenes/${id}`);
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue('Failed to sync order data');
      }
    } catch (error) {
      console.error('Could not sync order data:', error);
      return rejectWithValue(error.message);
    }
  }
);



export const fetchOrders = createAsyncThunk(
  'multiStepForm/fetchOrders',
  async ({ startDate, endDate }) => {  // Destructure the argument to get startDate and endDate
    try {
      // Adjust the URLs to include the date range query parameters
      const ordersUrl = new URL(`${process.env.REACT_APP_NODE_END_POINT}/ordenes`);
      if (startDate && endDate) {
        ordersUrl.searchParams.append('startDate', startDate);
        ordersUrl.searchParams.append('endDate', endDate);
      }
      const productsUrl = `${process.env.REACT_APP_NODE_END_POINT}/productos`;

      const [ordersResponse, productsResponse] = await Promise.all([
        axios.get(ordersUrl.toString()),  // Use toString() to get the full URL with query params
        axios.get(productsUrl),
      ]);

      const labelsData = productsResponse.data;  // Labels
      const ordersData = Array.isArray(ordersResponse.data) ? ordersResponse.data : [];

      const allOrders = ordersData.map((order) => {
        const tableDataItems = order.formData && Array.isArray(order.formData.tableData) ? order.formData.tableData : [];
        
        const tableData = tableDataItems.map((item) => {
          const correspondingLabel = labelsData.find(label => label.name === item.name);
          return {
            ...item,
            label: correspondingLabel ? correspondingLabel.label : 'No Label',
          };
        });

        return {
          ...order,
          formData: {
            ...order.formData,
            tableData,
          }
        };
      });
      console.log('Fetched orders count:', allOrders.length); 
      return allOrders;
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  }
);


export const addProduct = createAsyncThunk(
  'multiStepForm/addProduct',
  async (payload, { rejectWithValue }) => {
    const { newRow, id } = payload;

    try {
      const existingOrderResponse = await axios.get(`${process.env.REACT_APP_NODE_END_POINT}/ordenes/${id}`);
      const existingOrderData = existingOrderResponse.data;
      const maxKey = Math.max(...existingOrderData.formData.tableData.map(item => parseInt(item.key, 10)), -1);
      const newKey = maxKey + 1;
      newRow.key = newKey.toString();

      const { _id, ...otherExistingData } = existingOrderData;

      const response = await axios.put(`${process.env.REACT_APP_NODE_END_POINT}/ordenes/${id}`, {
        ...otherExistingData,
        formData: {
          ...existingOrderData.formData,
          tableData: [...existingOrderData.formData.tableData, newRow],
        },
      });

      if (response.status === 200) {
        return { newRow, id };
      } else {
        return rejectWithValue('Failed to add product');
      }
    } catch (error) {
      console.error('Could not add product to order:', error);
      return rejectWithValue(error.message);
    }
  }
);



export const multiStepFormSlice = createSlice({
  name: 'multiStepForm',
  initialState,
  reducers: {

    // Existing reducers
    updateFormData: (state, action) => {
      const payloadKeys = Object.keys(action.payload);
      payloadKeys.forEach((key) => {
        if (state.formData[key] && typeof state.formData[key] === 'object' && !Array.isArray(state.formData[key])) {
          state.formData[key] = {
            ...state.formData[key],
            ...action.payload[key],
          };
        } else {
          state.formData[key] = action.payload[key];
        }
      });
    },
    updateTableData: (state, action) => {
    

      state.formData.tableData = action.payload;
    },
    
    updateStep: (state, action) => {
      state.step = action.payload;
    },



    deleteRow: (state, action) => {
      const { id, key } = action.payload;
      const order = state.allOrders.find((o) => o._id === id);
      if (order) {
        order.formData.tableData = order.formData.tableData.filter((item) => item.key !== key);
      }
    },
  
    deleteOrder: (state, action) => {
      const id = action.payload;
      state.allOrders = state.allOrders.filter(order => order._id !== id);
    },
    
    
    updateField: (state, action) => {
      const { id, key, field, value } = action.payload;
      const order = state.allOrders.find((o) => o._id === id);
      if (order) {
        const itemIndex = order.formData.tableData.findIndex((item) => item.key === key);
        if (itemIndex > -1) {
          order.formData.tableData[itemIndex][field] = value;
        } else {
          console.warn(`Item with key ${key} not found`);
        }
      }
    },
    
    // New reducer to handle the business data
    setBusinessData: (state, action) => {
      state.formData.businessData = action.payload;
    },
    resetFormData: (state) => {
      state.formData = initialState.formData; // Resetting formData to initial state
      state.step = initialState.step; // Optionally, you can reset the step as well
    },
    
    

    toggleSwitch: (state, action) => {
      const { id, key, status } = action.payload;
      const order = state.allOrders.find((o) => o._id === id);
      if (order) {
        const itemIndex = order.formData.tableData.findIndex((item) => item.key === key);
        if (itemIndex > -1) {
          order.formData.tableData[itemIndex].state = status;
        }
      }
    },
  
    toggleApproval: (state, action) => {
      const { id, key, status } = action.payload;
      const order = state.allOrders.find((o) => o._id === id);
      if (order) {
        const itemIndex = order.formData.tableData.findIndex((item) => item.key === key);
        if (itemIndex > -1) {
          order.formData.tableData[itemIndex].approval = status;
        }
      }
    },
 
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.allOrders = action.payload;
      })
      .addCase(addProduct.fulfilled, (state, action) => {
        const { newRow, id } = action.payload;
        const order = state.allOrders.find((o) => o._id === id);
        if (order) {
          order.formData.tableData.push(newRow);
        }
      })
      .addCase(adjustTime.fulfilled, (state, action) => {
        const { id, newProductionTime, newDeliveryTime, newTimeRange  } = action.payload;
        const order = state.allOrders.find((o) => o._id === id);
      
        if (order) {
        
          if (newProductionTime !== null) {  // Add validation
            order.formData.productionTime = newProductionTime;
          }
          if (newDeliveryTime !== null) {  // Add validation
            order.formData.deliveryTime = newDeliveryTime;
          }
          if (newTimeRange) {  // Add validation
            order.formData.timeRange = newTimeRange;
          }
        } else {
          console.log(`No order found for ID: ${id}`);  // Log when order not found
        }
      })
      builder.addCase(syncOrderData.fulfilled, (state, action) => {
        const index = state.allOrders.findIndex(order => order._id === action.payload._id);
        if (index !== -1) {
          state.allOrders[index] = action.payload;
        } else {
          state.allOrders.push(action.payload);
        }
      });
      

  }

});


// Export actions for use within your components
export const {
  updateFormData, 
  updateTableData, 
  updateStep, 
  setBusinessData, 
  resetFormData,
  toggleApproval, 
  toggleSwitch,
  updateField, 
  deleteRow,
deleteOrder,
} = multiStepFormSlice.actions;
// Export the reducer
export default multiStepFormSlice.reducer