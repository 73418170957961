import React, { useState, useEffect } from 'react';
import { Layout, Button, Typography, Card, Form, Input } from 'antd';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../components/redux/usersSlice';

const { Title } = Typography;
const { Header, Content } = Layout;

const SignIn = (props) => {
  const [state, setState] = useState({
    password: '',
    username: '',
  });


  const dispatch = useDispatch();
  const authStatus = useSelector((state) => state.userManagement.authStatus);


  useEffect(() => {
    let isMounted = true;  // Initially, the component is assumed to be mounted.

    if (authStatus === 'authenticated' && isMounted && props.location.pathname !== '/lista-ordenes') {
      handleSuccess(`Welcome, ${state.username}`);
      setTimeout(() => {
        props.history.push('/lista-ordenes');
      }, 500);
    }

    return () => { isMounted = false; };  // Cleanup: mark the component as unmounted.
}, [authStatus, state.username, props.location.pathname, props.history]);




  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  };

  const handleError = (err) => {
    toast.error(err, {
      position: 'bottom-left',
    });
  };

  const handleSuccess = (msg) => {
    toast.success(msg, {
      position: 'bottom-left',
    });
  };

  const onFinish = async (values) => {
    try {
      await dispatch(loginUser(values));
  
     
      setState(prevState => ({ ...prevState, password: '' }));
    } catch (error) {
      console.log(error);
      handleError("An error occurred while trying to login");
    }
  };
  
  

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <div className="layout-default ant-layout layout-sign-up">
        <Header>
          <div className="header-col header-brand">
            <h5>Arancibia Eventos</h5>
          </div>
        </Header>
        <Content className="p-0">
          <div className="sign-up-header">
            <div className="content">
              <Title>Iniciar Sesión</Title>
            </div>
          </div>
          <Card className="card-signup header-solid h-full ant-card pt-0" bordered={false}>
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="row-col"
            >
              <Form.Item
                name="username"
                rules={[{ required: true, message: 'Please input your username!' }]}
              >
                <Input
                  placeholder="Nombre"
                  name="username"
                  value={state.username}
                  onChange={handleOnChange}
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
              >
                <Input.Password 
                  placeholder="Clave"
                  name="password"
                  value={state.password}
                  onChange={handleOnChange}
                />
              </Form.Item>
              <Form.Item>
                <Button style={{ width: '100%' }} type="primary" htmlType="submit">
                  ENTRAR
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Content>
      </div>
    </>
  );
};

export default withRouter(SignIn);
