import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import "../../assets/styles/main.css";
import { Button, Form, Input, Row, Col, Card, message } from "antd";
import { format } from "rut.js";
import InputMask from "react-input-mask";
import { updateFormData } from "../redux/multiStepFormSlice";

let timerId = null;

// Adapted Create Function
export default function CreateAdapted({ next,  formData }) {
  const dispatch = useDispatch(); // Redux dispatch
  const [form] = Form.useForm();
  const [adaptedClientData, setAdaptedClientData] = useState(null);
  const adaptedHistory = useHistory();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneWarning, setPhoneWarning] = useState(null);
  const [showCreateClientButton, setShowCreateClientButton] = useState(false);

  const formItemLayout = { labelCol: { span: 8 }, wrapperCol: { span: 14 } };
  const buttonItemLayout = { wrapperCol: { span: 14, offset: 8 } };

  useEffect(() => {
    if (formData) {
      form.setFieldsValue(formData);
    }
  }, [formData, form]);

  const createClient = async () => {
    try {
      const values = await form.validateFields();
      await fetch(`${process.env.REACT_APP_NODE_END_POINT}/record`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
      message.success("Cliente Creado");

      

      form.setFieldsValue(values);
      setShowCreateClientButton(false);
      next();
    } catch (error) {
      console.error("Failed to create client:", error);
    }
  };

  const searchAdaptedClient = async (rut) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_NODE_END_POINT}/record?rut=${rut}`);
      const data = await response.json(); // Declare "data" here
      if (Array.isArray(data) && data.length > 0 && data[0].Nombre) {
        setAdaptedClientData(data[0]);
        setShowCreateClientButton(false); // Hide the button if client is found
      } else {
        setAdaptedClientData(null);
        setShowCreateClientButton(true); // Show the button if client is not found
      }
    } catch (error) {
      console.error("Failed to search client:", error);
    }
  };

  const handleBlur = () => {
    const totalLength = phoneNumber.length;
    if (totalLength < 15) {
      setPhoneWarning("No válido");
    } else {
      setPhoneWarning(null);
    }
  };

  const handleAdaptedRUTChange = (e) => {
    let rutValue = e.target.value;

    if (!rutValue || rutValue === "-") {
      form.resetFields();
      dispatch(updateFormData({ ...formData, rut: rutValue }));
      setAdaptedClientData(null);
      setShowCreateClientButton(false); // Hide the button
      return;
    }

    rutValue = format(rutValue);

    form.setFieldsValue({ rut: rutValue }); // Update Ant Design form
    dispatch(updateFormData({ rut: rutValue }));

    if (rutValue.length < 11) {
      setShowCreateClientButton(false); // Hide the button if less than 11 digits
    }

    if (rutValue.length === 11) {
      if (timerId) {
        clearTimeout(timerId); // Clear any existing timers
      }

      // Set a new timer
      timerId = setTimeout(() => {
        searchAdaptedClient(rutValue);
      }, 500); // 300 ms delay for 11 digits
    }

    if (rutValue.length === 12) {
      if (timerId) {
        clearTimeout(timerId); // Clear any existing timers
      }

      searchAdaptedClient(rutValue); // No delay for 12 digits
    }
  };

  const useAdaptedClientData = () => {
    form.setFieldsValue(adaptedClientData);
    dispatch(updateFormData(adaptedClientData));
  };

  const onAdaptedSubmit = async (values) => {
    try {
      await fetch(`${process.env.REACT_APP_NODE_END_POINT}/record`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
      form.resetFields();
      adaptedHistory.push("/");
    } catch (error) {
      window.alert(error);
    }
  };
  return (
    <Card title="Seleccionar Cliente" style={{ width: "100%" }}>
      <div>
        <Row>
          <Col span={4}></Col>
        </Row>
        <Form
          {...formItemLayout}
          layout="horizontal"
          form={form}
          onFinish={onAdaptedSubmit}
          style={{ maxWidth: 600 }}
        >
          <Form.Item
            label="RUT"
            name="rut"
            rules={[{ required: true, message: "Por favor, ingrese su RUT." }]}
          >
            <Input
              placeholder="Insertar RUT"
              onChange={handleAdaptedRUTChange}
              maxLength={12}
            />
          </Form.Item>
          {adaptedClientData && (
            <Form.Item {...buttonItemLayout}>
              <Button onClick={useAdaptedClientData}>
                Usar datos: {adaptedClientData.Nombre}
              </Button>
            </Form.Item>
          )}

          <Form.Item
            label="Nombre"
            name="Nombre"
            rules={[
              { required: true, message: "Por favor, ingrese su nombre." },
            ]}
          >
            <Input
              placeholder="Insertar Nombre"
              onChange={(e) =>
                dispatch(
                  updateFormData({ ...formData, Nombre: e.target.value })
                )
              }
              onInput={(e) =>
                dispatch(
                  updateFormData({ ...formData, Nombre: e.target.value })
                )
              }
            />
          </Form.Item>

          <Form.Item
            label="Número"
            name="Numero"
            rules={[
              { required: true, message: "Por favor, ingrese su número." },
            ]}
          >
            <InputMask
              mask="+56 9 9999 9999"
              maskChar={null}
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
                dispatch(
                  updateFormData({ ...formData, Numero: e.target.value })
                );
              }}
              onInput={(e) =>
                dispatch(
                  updateFormData({ ...formData, Nombre: e.target.value })
                )
              }
              onBlur={handleBlur}
            >
              {(inputProps) => (
                <Input
                  {...inputProps}
                  type="tel"
                  placeholder="Insertar Número"
                />
              )}
            </InputMask>
            {phoneWarning && <div>{phoneWarning}</div>}
          </Form.Item>

          {showCreateClientButton && (
            <Form.Item {...buttonItemLayout}>
              <Button type="primary" onClick={createClient}>
                Crear Cliente
              </Button>
            </Form.Item>
          )}
        </Form>
      </div>
    </Card>
  );
}
