import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

const initialState = {
  orderData: null,
  status: 'idle',
  error: null
};

export const fetchOrder = createAsyncThunk('order/fetchOrder', async (id) => {
  const response = await axios.get(`${process.env.REACT_APP_NODE_END_POINT}/ordenes/${id}`);
  return response.data;
});

export const updateOrder = createAsyncThunk('order/updateOrder', async ({ id, data }) => {
  await axios.patch(`${process.env.REACT_APP_NODE_END_POINT}/ordenes/details/${id}`, data);
  return { id, data };
});

const orderDeliverySlice = createSlice({
  name: 'order',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrder.fulfilled, (state, action) => {
        state.orderData = action.payload;
        state.status = 'succeeded';
      })
      .addCase(updateOrder.fulfilled, (state, action) => {
        if (state.orderData && state.orderData._id === action.payload.id) {
          state.orderData.formData = action.payload.data;
        }
      });
  }
});

export default orderDeliverySlice.reducer;
