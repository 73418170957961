import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from "react-redux";

const PrivateRoute = ({ component: Component, roles = [], ...rest }) => {
  const currentUserRole = useSelector(state => state.userManagement.currentUserRole);
  const authStatus = useSelector(state => state.userManagement.authStatus);

  console.log("Auth Status:", authStatus);
  console.log("Current User Role:", currentUserRole);
  console.log("Allowed Roles:", roles);

  return (
    <Route
      {...rest}
      render={props => {
        if (authStatus === 'loading') {
          return <div>Loading...</div>;
      }
      
      if (authStatus === 'failed' || authStatus === 'unauthenticated') {
          return <Redirect to="/sign-in" />;
      }
        if (authStatus === 'authenticated' && roles.includes(currentUserRole)) {
          return <Component {...props} />;
        } 
        return <Redirect to="/sign-in" />;
      }}
    />
  );
  
};

export default PrivateRoute;
