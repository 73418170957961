import React, { useEffect, useState } from 'react';
import ReactApexChart from "react-apexcharts";
import { Typography } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import lineChartConfig from "./configs/lineChart";

const { Title, Paragraph } = Typography;

function LineChart() {
  const [salesData, setSalesData] = useState([]);

  useEffect(() => {
    // Fetch data from the endpoint
    fetch(`${process.env.REACT_APP_NODE_END_POINT}/graficos/monthly-sales`)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        const sales = Array(12).fill(0); // Default sales data with 0s
        data.forEach(item => {
          sales[item._id.month - 1] = item.totalSales; // MongoDB month index starts at 1
        });
        setSalesData(sales);
      });
  }, []);

  const lineChart = {
    ...lineChartConfig,
    series: [{
      name: "Sales",
      data: salesData,
      offsetY: 0,
    }],
    options: {
      ...lineChartConfig.options,
      xaxis: {
        ...lineChartConfig.options.xaxis,
        categories: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
      }
    }
  };

  return (
    <>
      <div className="linechart">
        <div>
          <Title level={5}>Ventas por Mes</Title>
          <Paragraph className="lastweek">
            Año Actual
          </Paragraph>
        </div>
        <div className="sales">
          <ul>
            <li>{<MinusOutlined />} Montos</li>
          </ul>
        </div>
      </div>

      <ReactApexChart
        className="full-width"
        options={lineChart.options}
        series={lineChart.series}
        type="area"
        height={350}
        width={"100%"}
      />
    </>
  );
}

export default LineChart;
