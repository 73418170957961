import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateFormData, updateTableData } from "../redux/multiStepFormSlice";
import {
  Row,
  Col,
  Form,
  Table,
  Input,
  InputNumber,
  Button,
  AutoComplete,
  Select,
  Card,
  Typography,
message,
} from "antd";

const ProductTable = ({  formData, setFormData }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [tableForm] = Form.useForm();
  const tableData = useSelector(
    (state) => state.multiStepForm.formData.tableData
  );
  const [options, setOptions] = useState([]); // AutoComplete options
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [quantity, setQuantity] = useState(0);
  const [finalPrice, setFinalPrice] = useState(0);
  const [editingKey, setEditingKey] = useState("");
  const autoCompleteRef = useRef(null);

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    children,
    ...restProps
  }) => {
    const inputNode = <Input />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: dataIndex === "notas" ? false : true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };


  
  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    tableForm.setFieldsValue({
      name: "",
      variant: "",
      quantity: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const save = async (key) => {
  
    try {
      const row = await tableForm.validateFields();
      const newData = [...tableData];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData[index] = {
          ...item,
          ...row,
          subtotal: item.price * row.quantity,
        };
        dispatch(updateTableData(newData));
        setEditingKey("");
        updateTableData(newData); // Update the parent state
        console.log("save. New tableData:", newData);
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const totalSum = tableData.reduce((sum, record) => sum + record.subtotal, 0);

  const fetchProductsByName = async (name) => {
    if (!name) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_NODE_END_POINT}/productos?name=${name}`
      );
      const data = await response.json();
      const filteredData = data.filter((item) =>
        item.name.toLowerCase().startsWith(name.toLowerCase())
      );
      setOptions(
        filteredData.map((item) => ({ value: item.name, data: item }))
      );
    } catch (error) {
      console.error("Failed to search product:", error);
    }
  };

  // Your existing useEffect for updating finalPrice

  // New useEffect for updating parent component's formData
  useEffect(() => {
   
    if (typeof updateData === "function") {
     

      dispatch(updateFormData({ tableData })); // Dispatch the updateFormData action to Redux store

      const updatedFormData = {
        ...formData,
        tableData, // This should be the updated tableData
      };
      setFormData(updatedFormData); // Assuming setFormData is passed from parent
     
    }
    
  }, [tableData, formData, setFormData, dispatch]); // Added additional dependencies, including dispatch

  useEffect(() => {
    if (selectedVariant && quantity) {
      setFinalPrice(selectedVariant.price * quantity);
    }
  }, [selectedVariant, quantity]);

  const handleNameChange = (value, isOptionSelected) => {
    
    if (!value) {
      form.resetFields();
      setSelectedProduct(null);
      setSelectedVariant(null);
      setOptions([]);
      return;
    }

    const selected = options.find((option) => option.value === value);
    if (selected) {
      setSelectedProduct(selected.data);
      if (
        isOptionSelected &&
        selected.data.variants &&
        selected.data.variants.length > 0
      ) {
        const firstVariant = selected.data.variants[0];
        setSelectedVariant(firstVariant);
        form.setFieldsValue({ variantes: firstVariant.name });
      }
    }

    if (!isOptionSelected) {
      fetchProductsByName(value);
    }
  };

  const handleSelectVariant = (value) => {
    const variant = selectedProduct.variants.find((v) => v.name === value);
    setSelectedVariant(variant);
  };
  // Handle form submission and add data to table
  const handleAddProduct = () => {
 

    // Extract form values
    const formData = form.getFieldsValue();

    if (!formData.cantidad || formData.cantidad < 1) {
      message.warning('Cantidad Minima: 1');
      return; // Exit the function early
  }
  const productLabel = selectedProduct ? selectedProduct.label : null;
    // Create new row data based on form values
    const newRow = {
      key: tableData.length.toString(), // Replace with unique identifiers
      name: formData.name,
      variant: formData.variantes,
      quantity: formData.cantidad,
      price: finalPrice / formData.cantidad,
      individualPrice: selectedVariant ? selectedVariant.price : 0,
      subtotal: finalPrice,
      notas: formData.comment,
      state: false,
      approval: false,
      label: productLabel, 
    };

    // Create the updated table data
    const updatedTableData = [...tableData, newRow];

    // Update Redux store
    dispatch(updateTableData(updatedTableData));

    
    // Reset form fields
    form.resetFields();
    setSelectedProduct(null);
    setSelectedVariant(null);
    setQuantity(0);
    setFinalPrice(0);

    // Update the parent state
    updateTableData(updatedTableData);

    setTimeout(() => {
      if (autoCompleteRef.current) {
          autoCompleteRef.current.focus();
      }
  }, 0);

  };

  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
      editable: false,
    },
    {
      title: "Variante",
      dataIndex: "variant",
      editable: false,
    },
    {
      title: "Cantidad",
      dataIndex: "quantity",
      editable: true,
    },
    {
      title: "Valores",
      dataIndex: "subtotal",
    },
    {
      title: "Notas",
      dataIndex: "notas",
      editable: true,
      width: "25%",
    },
    {
      title: "Editar",
      dataIndex: "operation",
      width: "8%",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{ marginRight: 8 }}
            >
              Listo
            </Typography.Link>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            Editar
          </Typography.Link>
        );
      },
    },
    {
      title: "Borrar",
      dataIndex: "delete",
      width: "10%",
      render: (_, record) => (
        <Typography.Link
          onClick={() => {
            // Filter out the row that needs to be deleted
            const newTableData = tableData.filter(
              (item) => item.key !== record.key
            );

            // Update the Redux store
            dispatch(updateTableData(newTableData));

            // Update the parent state, if needed
            if (typeof updateTableData === "function") {
              updateTableData(newTableData);
            }
          }}
        >
          Borrar
        </Typography.Link>
      ),
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Card title="Seleccionar Productos">
      <Row gutter={16}>
        <Col span={7} style={{ width: "30%" }}>
          <Form
            form={form}
            layout="vertical"
            style={{ width: "100%" }}
            initialValues={{ cantidad: 0 }}
          >
        <Form.Item
  label="Producto"
  name="name"
  style={{ marginBottom: "4px" }}
>
<AutoComplete
  ref={autoCompleteRef}
  options={options}
  placeholder="Insertar nombre del producto"
  onSelect={(value) => {
    handleNameChange(value, true);
    setTimeout(() => autoCompleteRef.current.focus(), 0);
  }}
  onSearch={(value) => {
    handleNameChange(value, false);
    if (!value) {
      setTimeout(() => autoCompleteRef.current.focus(), 0);
    }
  }}
  filterOption={false}
/>


</Form.Item>
            <Form.Item
              label="Variante"
              name="variantes"
              style={{ marginBottom: "4px" }}
            >
              <Select
                disabled={!selectedProduct}
                onChange={handleSelectVariant}
              >
                {selectedProduct &&
                  selectedProduct.variants.map((variant, index) => (
                    <Select.Option key={index} value={variant.name}>
                      {variant.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>

            <Row gutter={8}>
              <Col span={12}>
              <Form.Item
    label="Cantidad"
    style={{ marginBottom: "4px" }}
>
    <div>
        <Button
            type="link"
            size="small"
            onClick={() => {
                const newQuantity = (quantity || 0) + 25;
                setQuantity(newQuantity);
                form.setFieldsValue({ cantidad: newQuantity });
            }}
        >
            +25
        </Button>
        <Button
            type="link"
            size="small"
            onClick={() => {
                const newQuantity = (quantity || 0) + 50;
                setQuantity(newQuantity);
                form.setFieldsValue({ cantidad: newQuantity });
            }}
        >
            +50
        </Button>
        <Form.Item name="cantidad">
            <InputNumber
                min={0}
                value={quantity}
                onChange={(val) => {
                    setQuantity(val);
                    form.setFieldsValue({ cantidad: val });
                }}
            />
        </Form.Item>
    </div>
</Form.Item>

              </Col>
              <Col span={12}>
                <Form.Item label="Sub Total" style={{ marginBottom: "4px" }}>
                  <span>{finalPrice > 0 ? finalPrice : "-"}</span>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label="Notas"
              name="comment"
              style={{ marginBottom: "4px" }}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" onClick={handleAddProduct}>
                Agregar
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col span={17} style={{ width: "70%" }}>
          <Form form={tableForm} component={false}>
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              dataSource={tableData}
              columns={mergedColumns}
              rowClassName="editable-row"
              pagination={{ pageSize: 5 }}
              footer={() => (
                <div style={{ textAlign: "right", fontWeight: "bold" }}>
                  Total: {totalSum}
                </div>
              )}
            />
          </Form>
        </Col>
      </Row>
    </Card>
  );
};

export default ProductTable;
