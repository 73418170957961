import React, { useEffect, useState, useRef } from "react";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  Button,
  Avatar,
  Typography,
  Table,
  Modal,
  Pagination,
  Input,
  Space,
  Popconfirm,
} from "antd";
import { SearchOutlined } from '@ant-design/icons';
import Edit from '../components/clientes/edit';
import Create from '../components/clientes/create';

const { Title } = Typography;

function ClientList() {
  const history = useHistory(); 
  const [records, setRecords] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [isNewClientModalVisible, setIsNewClientModalVisible] = useState(false);


  
  useEffect(() => {
    async function getRecords() {
      const response = await fetch(`${process.env.REACT_APP_NODE_END_POINT}/record/`);
      if (!response.ok) {
        const message = `An error occurred: ${response.statusText}`;
        window.alert(message);
        return;
      }

      const fetchedRecords = await response.json();
      setRecords(fetchedRecords);
    }

    getRecords();
  }, []);


  

  const showNewClientModal = () => {
    setCurrentRecord(null); // setting to null to signify a new client creation
    setIsNewClientModalVisible(true);
  };

  const handleCreate = (newClient) => {
    setRecords(prevRecords => [...prevRecords, newClient]);
    setIsNewClientModalVisible(false); // Close the modal after creating a new client
  };

  

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => 
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
  });

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'Nombre',
      key: 'nombre',
      ...getColumnSearchProps('Nombre'),
      render: (text, record) => (
        <>
          <Avatar.Group>
            <div className="avatar-info">
              <Title level={5}>{record.Nombre}</Title>
            </div>
          </Avatar.Group>
        </>
      ),
    },
    {
      title: 'Número',
      dataIndex: 'Numero',
      key: 'numero',
      ...getColumnSearchProps('Numero'),
    },
    {
      title: 'RUT',
      dataIndex: 'rut',
      key: 'rut',
      ...getColumnSearchProps('rut'), // Added search props for 'RUT'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Modificar',
      key: 'action',
      render: (record) => (
        <>
        <Button type="primary" onClick={() => showEditModal(record)}>
        <EditOutlined />
        </Button>
        <Popconfirm
      title="Confirmar elminiación."
      onConfirm={() => deleteRecord(record._id)}
      onCancel={() => console.log('User canceled delete')}
      okText="Si"
      cancelText="No"
    >
        <Button type="dashed" >
        <DeleteOutlined />
        </Button>
        </Popconfirm>
        
      </>
      ),
    },
  ];
  const showEditModal = (record) => {
    setCurrentRecord(record);
    setIsModalVisible(true);
  };

  const deleteRecord = async (id) => {
    await fetch(`${process.env.REACT_APP_NODE_END_POINT}/record/${id}`, {
      method: "DELETE",
    });
    const newRecords = records.filter((el) => el._id !== id);
    setRecords(newRecords);
  };

  const handleUpdate = (updatedRecord) => {
    setRecords(prevRecords =>
      prevRecords.map(rec => rec._id === updatedRecord._id ? updatedRecord : rec)
    );
  };
  const goToBusinessList = () => {
    history.push("/businesses-list");  // <-- Navigate to businessesList.js
  };


  return (
    <>
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col lg={24} md={24} sm={24} xs={24}>
          <Card
            title={
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>Lista de clientes</span>
                <div>
          
                  {/* New Button for navigating to businessesList.js */}
                  <Button type="primary" onClick={goToBusinessList} style={{ marginLeft: '16px' }}>
                    Ver Empresas
                  </Button>
                </div>
              </div>
            }
          >
             <Table
  columns={columns}
  dataSource={records}
  rowKey={record => record._id}
  pagination={{
    current: currentPage,
    pageSize: itemsPerPage,
    total: records.length,
    showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} clientes`,
    onChange: (page, pageSize) => {
      setCurrentPage(page);
      setItemsPerPage(pageSize);
    },
    showSizeChanger: true,
    pageSizeOptions: ['5', '10', '20', '50', '100'],
  }}
/>

            </Card>
          </Col>
        </Row>
      </div>
      <Modal
  title="Editar Cliente"
  visible={isModalVisible}
  onCancel={() => setIsModalVisible(false)}
  footer={null}
>
  <Edit 
    record={currentRecord} 
    handleUpdate={handleUpdate} 
    onClose={() => setIsModalVisible(false)} 
  />
</Modal>
<Modal
        title="Create New Client"
        visible={isNewClientModalVisible}
        onCancel={() => setIsNewClientModalVisible(false)}
        footer={null}
      >
        {/* Notice that I pass handleCreate and also a function to close the modal */}
        <Create handleCreate={handleCreate} onClose={() => setIsNewClientModalVisible(false)} />
      </Modal>
    </>
  );
}

export default ClientList;