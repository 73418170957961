import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setBusinessData, updateFormData } from '../redux/multiStepFormSlice'; // Change this path
import { Button, Form, Input, message, Row, Col, Card } from 'antd';
import InputMask from 'react-input-mask';
import { format } from 'rut.js';

export default function BusinessForm() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const formData = useSelector((state) => state.multiStepForm.formData);
  const businessData = formData.businessData;
  const [fetchedBusinessData, setFetchedBusinessData] = useState(null);
  const [businessPhoneNumber, setBusinessPhoneNumber] = useState("");
  const [phoneWarning, setPhoneWarning] = useState(null);
  const [showCreateBusinessButton, setShowCreateBusinessButton] = useState(false);
  const [hasSearchedRut, setHasSearchedRut] = useState(false);

  const formItemLayout = { labelCol: { span: 8 }, wrapperCol: { span: 14 } };
  const buttonItemLayout = { wrapperCol: { span: 14, offset: 8 } };

  useEffect(() => {
    if (formData.businessData) {
      form.setFieldsValue(formData.businessData);
    }
  }, [formData.businessData, form]);

  const createBusiness = async () => {
    try {
      const values = await form.validateFields();
      await fetch(`${process.env.REACT_APP_NODE_END_POINT}/businesses`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });

      message.success('Empresa Creada');
      setShowCreateBusinessButton(false);

      
      dispatch(setBusinessData(values));

    } catch (error) {
      message.error("Error creating business");
      console.error("Error:", error);
    }
  };
  
  
  const searchBusiness = async (rutEmpresa) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_NODE_END_POINT}/businesses?rutEmpresa=${rutEmpresa}`);
      const data = await response.json();
      setHasSearchedRut(true); 
      if (Array.isArray(data) && data.length > 0 && data[0].nombreFantasia) {
        setFetchedBusinessData(data[0]);
        setShowCreateBusinessButton(false);
    } else {
        setFetchedBusinessData(null);
        setShowCreateBusinessButton(true);
    }

    } catch (error) {
      console.error("Failed to search business:", error);
    }
  };
  
  const useBusinessData = () => {
    if(fetchedBusinessData){
        form.setFieldsValue(fetchedBusinessData);
        dispatch(setBusinessData(fetchedBusinessData));
    }
};


  const handleBlur = () => {
    const totalLength = businessPhoneNumber.length;
    if (totalLength < 15) {
      setPhoneWarning('No válido');
    } else {
      setPhoneWarning(null);
    }
  };

  const handleRUTChange = (e) => {
    let rutValue = e.target.value;
    if (!rutValue) {
      dispatch(setBusinessData(null)); // Remove business data when RUT is empty
      setShowCreateBusinessButton(false);
      setHasSearchedRut(false);  // Reset the search flag
      form.resetFields();
      return;
    }

    rutValue = format(rutValue);
    form.setFieldsValue({ rutEmpresa: rutValue });

    if (rutValue.length >= 12) {
      searchBusiness(rutValue);  // Assuming this method sets the businessData state if a match is found
      setHasSearchedRut(true);   // Indicate a search was performed
    } else {
      // Hide button if RUT is incomplete
      setShowCreateBusinessButton(false);
      setHasSearchedRut(false);  // Reset the search flag
      dispatch(setBusinessData(null)); // Remove businessData if RUT is not complete
    }
};


 const updateFormWithBusinessData = (values) => {
    const updatedFormData = {
      ...formData, // Copy all existing formData keys and values
      businessData: { ...values } // Update the businessData key with the new values from the form
    };
    dispatch(updateFormData(updatedFormData)); // Dispatch updated formData to Redux store
  };

  const onSubmit = async (values) => {
    try {
      await fetch(`${process.env.REACT_APP_NODE_END_POINT}/businesses`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
  
      message.success('Empresa Creada');
      setShowCreateBusinessButton(false);
      form.resetFields();
  
      // Update Redux store
      dispatch(updateFormData({ ...formData, businessData: { ...values } })); // Updating businessData in Redux store
      updateFormWithBusinessData(values); // Updating formData in Redux store using the new function
  
    } catch (error) {
      window.alert(error);
    }
  };
  
 

  return (
    <Card title="Seleccionar Empresa" style={{ width: '100%' }}>
      <div>
        <Row>
          <Col span={4}></Col>
        </Row>
  
        <Form
        {...formItemLayout}
        layout="horizontal"
        form={form}
        onFinish={onSubmit}
        style={{ maxWidth: 600 }}
      >
          <Form.Item 
              label="RUT Empresa" 
              name="rutEmpresa"
              rules={[{ required: true, message: 'Por favor, ingrese el RUT de la empresa.' }]}
          >
              <Input placeholder="Insertar RUT Empresa" onChange={handleRUTChange} maxLength={12} />
          </Form.Item>

          {fetchedBusinessData && hasSearchedRut && (
    <Form.Item {...buttonItemLayout}>
        <Button onClick={useBusinessData}>Usar datos: {fetchedBusinessData.nombreFantasia}</Button>
    </Form.Item>
)}
  
  <Form.Item 
          label="Nombre Fantasía" 
          name="nombreFantasia"
          rules={[{ required: true, message: 'Por favor, ingrese el nombre de fantasía.' }]}
        >
          <Input 
            placeholder="Insertar Nombre Fantasía" 
            onChange={(e) => dispatch(updateFormData({ 
              ...formData, 
              businessData: { ...businessData, nombreFantasia: e.target.value || "" } 
            }))}
            onInput={(e) => dispatch(updateFormData({ 
              ...formData, 
              businessData: { ...businessData, nombreFantasia: e.target.value || "" } 
            }))}
          />
        </Form.Item>
  
        <Form.Item 
          label="Número Contacto" 
          name="numeroContacto"
          rules={[{ required: true, message: 'Por favor, ingrese su número.' }]}
        >
         <InputMask
  mask="+56 9 9999 9999"
  maskChar={null}
  value={businessPhoneNumber} // Make it controlled
  onChange={(e) => {
    setBusinessPhoneNumber(e.target.value); // Update local state
    dispatch(updateFormData({ 
      ...formData, 
      businessData: { ...businessData, numeroContacto: e.target.value } 
    }));
  }}
  onBlur={handleBlur} // Use a dedicated blur handler
>
  {(inputProps) => <Input {...inputProps} type="tel" placeholder="Insertar Número" />}
</InputMask>
{phoneWarning && <div>{phoneWarning}</div>}
</Form.Item>
        <Form.Item 
          label="Correo Contacto" 
          name="correoContacto"
          rules={[{ required: true, message: 'Por favor, ingrese el correo de contacto.' }]}
        >
          <Input 
            type="email" 
            placeholder="Insertar Correo Contacto"
            onChange={(e) => dispatch(updateFormData({ 
              ...formData, 
              businessData: { ...businessData, correoContacto: e.target.value } 
            }))}
            onInput={(e) => dispatch(updateFormData({ 
              ...formData, 
              businessData: { ...businessData, correoContacto: e.target.value } 
            }))}
          />
        </Form.Item>
  
        <Form.Item 
  label="Razón Social" 
  name="razonSocial"
  rules={[{ required: true, message: 'Por favor, ingrese la razón social.' }]}
>
  <Input 
    placeholder="Insertar Razón Social"
    onChange={(e) => dispatch(updateFormData({ 
      ...formData, 
      businessData: { ...businessData, razonSocial: e.target.value } 
    }))}
    onInput={(e) => dispatch(updateFormData({ 
      ...formData, 
      businessData: { ...businessData, razonSocial: e.target.value } 
    }))}
  />
</Form.Item>
  
<Form.Item 
  label="Giro Comercial" 
  name="giroComercial"
  rules={[{ required: true, message: 'Por favor, ingrese el giro comercial.' }]}
>
  <Input 
    placeholder="Insertar Giro Comercial"
    onChange={(e) => dispatch(updateFormData({ 
      ...formData, 
      businessData: { ...businessData, giroComercial: e.target.value } 
    }))}
    onInput={(e) => dispatch(updateFormData({ 
      ...formData, 
      businessData: { ...businessData, giroComercial: e.target.value } 
    }))}
  />
</Form.Item>
  
<Form.Item 
  label="Dirección Comercial" 
  name="direccionComercial"
  rules={[{ required: true, message: 'Por favor, ingrese la dirección comercial.' }]}
>
  <Input 
    placeholder="Insertar Dirección Comercial"
    onChange={(e) => dispatch(updateFormData({ 
      ...formData, 
      businessData: { ...businessData, direccionComercial: e.target.value } 
    }))}
    onInput={(e) => dispatch(updateFormData({ 
      ...formData, 
      businessData: { ...businessData, direccionComercial: e.target.value } 
    }))}
  />
</Form.Item>
  
          {showCreateBusinessButton && (
    <Form.Item>
        <Button type="primary" onClick={createBusiness}>Crear Empresa</Button>
    </Form.Item>
)}
        </Form>
      </div>
    </Card>
  );
};  