// rootReducer.js
import { combineReducers } from '@reduxjs/toolkit';
import multiStepFormReducer from './multiStepFormSlice'; // The reducer from your form and orders slice
import userReducer from './usersSlice'; // The new slice for users
import orderDeliveryReducer from './orderDeliverySlice'

const rootReducer = combineReducers({
  multiStepForm: multiStepFormReducer,  // your existing reducer for form and orders
  userManagement: userReducer,  // your new reducer for users
  orderDelivery: orderDeliveryReducer
});

export default rootReducer;
