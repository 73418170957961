import React, { useEffect, useState } from 'react';
import { Card, Row, Col } from "antd";
import EChart from '../components/chart/EChart';
import LineChart from '../components/chart/LineChart';
import { DollarCircleOutlined, SendOutlined, LineChartOutlined, ShopOutlined } from '@ant-design/icons';

function InfoCards() {
  const [salesToday, setSalesToday] = useState(0);
  const [salesWeek, setSalesWeek] = useState(0);
  const [ordersRetiro, setOrdersRetiro] = useState(0);
  const [ordersDespacho, setOrdersDespacho] = useState(0);

  useEffect(() => {
    Promise.all([
      fetch(`${process.env.REACT_APP_NODE_END_POINT}/graficos/sales-today`).then(res => res.json()),
      fetch(`${process.env.REACT_APP_NODE_END_POINT}/graficos/sales-week`).then(res => res.json()),
      fetch(`${process.env.REACT_APP_NODE_END_POINT}/graficos/orders-retiro`).then(res => res.json()),
      fetch(`${process.env.REACT_APP_NODE_END_POINT}/graficos/orders-despacho`).then(res => res.json())
    ]).then(([today, week, retiro, despacho]) => {
      setSalesToday(today);
      setSalesWeek(week);
      setOrdersRetiro(retiro);
      setOrdersDespacho(despacho);
    });
  }, []);

  return (
    <div className="info-cards">
    <Row gutter={[16, 16]}>
        <Col span={6} xs={24} sm={12} md={6}>
            <Card>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                        <h5>Ventas de Hoy</h5>
                        <h1>${salesToday || 0}</h1>
                    </div>
                    <div style={{ backgroundColor: '#1990FF', padding: '10px', borderRadius: '5px' }}>
                        <DollarCircleOutlined style={{ fontSize: '24px', color: 'white' }} />
                    </div>
                </div>
            </Card>
        </Col>
        
        <Col span={6} xs={24} sm={12} md={6}>
            <Card>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                        <h5>Venta Semanal</h5>
                        <h1>${salesWeek}</h1>
                    </div>
                    <div style={{ backgroundColor: '#1990FF', padding: '10px', borderRadius: '5px' }}>
                        <LineChartOutlined style={{ fontSize: '24px', color: 'white' }} />
                    </div>
                </div>
            </Card>
        </Col>

        <Col span={6} xs={24} sm={12} md={6}>
            <Card>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                        <h5>Retiros de Hoy</h5>
                        <h1>{ordersRetiro}</h1>
                    </div>
                    <div style={{ backgroundColor: '#1990FF', padding: '10px', borderRadius: '5px' }}>
                        <ShopOutlined style={{ fontSize: '24px', color: 'white' }} />
                    </div>
                </div>
            </Card>
        </Col>

        <Col span={6} xs={24} sm={12} md={6}>
            <Card>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                        <h5>Despachos de Hoy</h5>
                        <h1>{ordersDespacho}</h1>
                    </div>
                    <div style={{ backgroundColor: '#1990FF', padding: '10px', borderRadius: '5px' }}>
                        <SendOutlined style={{ fontSize: '24px', color: 'white' }} />
                    </div>
                </div>
            </Card>
        </Col>
    </Row>


  
        <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
        <Col xs={24} sm={24} md={12}>
                <Card>
                    <EChart />
                </Card>
            </Col>
            
            <Col xs={24} sm={24} md={12}>
                <Card>
                    <LineChart />
                </Card>
            </Col>
        </Row>
      
    </div>
  );
}

export default InfoCards;
