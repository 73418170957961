import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { DatePicker, Input, Button, Popconfirm, Select } from 'antd';
import { EditFilled } from "@ant-design/icons";
import dayjs from 'dayjs';
import { updateOrder } from '../redux/orderDeliverySlice';
import InputMask from 'react-input-mask';
import io from 'socket.io-client';
import axios from "axios";


const OrderComponent = ({ id }) => {
  const dispatch = useDispatch();

  const [editableData, setEditableData] = useState(null);
  const isMounted = useRef(true); // Use to track if component is mounted

 

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_NODE_END_POINT}/ordenes/${id}`);
      if (isMounted.current) {
        setEditableData(response.data.formData);
      }
    } catch (error) {
      console.error('Failed to fetch order:', error);
    }
  }, [id]); 

  useEffect(() => {
    const socket = io(`${process.env.REACT_APP_NODE_END_POINT}`);

    fetchData();

    socket.on("orderUpdated", (data) => {
      

      if (id === data.orderId) {
        fetchData();
      }
    });

    return () => {
      socket.off("orderUpdated");
      isMounted.current = false;
    };
  }, [id, fetchData]);  // Include fetchData in the dependency array of useEffect


  const handleCancel = () => {
    console.log("User cancelled the action.");
    // Any other logic you want to run when the user cancels can be added here.
  };  

  const handleSave = () => {
    dispatch(updateOrder({ id, data: editableData }));
  };

  if (!editableData) return null;

  return (
    <div>
    <div>
        <span style={{ display: 'inline-block', width: '30%' }}>Fecha:</span>
        <DatePicker style={{ width: '70%' }}
    format="DD-MM-YYYY"
    value={editableData.deliveryDate ? dayjs(editableData.deliveryDate) : null}
    onChange={(date) => {
        if (date) { // check if it's a valid date object
            setEditableData(prev => ({ ...prev, deliveryDate: date.toISOString() }));
        } else {
            setEditableData(prev => ({ ...prev, deliveryDate: null }));
        }
    }}
    
/>
</div>
<div>
    <span style={{ display: 'inline-block', width: '30%' }}>Entrega:</span>
    <Select 
        style={{ width: '70%' }} 
        value={editableData.selectedOption} 
        onChange={value => setEditableData(prev => ({ ...prev, selectedOption: value }))}
    >
        <Select.Option value="Retiro">Retiro</Select.Option>
        <Select.Option value="Despacho">Despacho</Select.Option>
    </Select>
</div>
        
<div>
    <span style={{ display: 'inline-block', width: '30%' }}>Dirección:</span>
    <Input
        style={{ width: '70%' }}
        placeholder=""
        value={editableData.address}
        onChange={e => setEditableData(prev => ({ ...prev, address: e.target.value }))}
    />
</div>
<div>
    <span style={{ display: 'inline-block', width: '30%' }}>Metodo de pago:</span>
    <Select 
        style={{ width: '70%' }} 
        value={editableData.paymentMethod}
        onChange={value => setEditableData(prev => ({ ...prev, paymentMethod: value }))}
    >
        <Select.Option value="Efectivo">Efectivo</Select.Option>
        <Select.Option value="RedCompra">RedCompra</Select.Option>
        <Select.Option value="Transferencia">Transferencia</Select.Option>
    </Select>
</div>
<div>
    <span style={{ display: 'inline-block', width: '30%' }}>Teléfono:</span>
    <InputMask
        style={{ width: '70%' }}
        mask="+56 9 9999 9999"
        maskChar={null}
        value={editableData.phoneNumber}
        onChange={(e) => setEditableData(prev => ({ ...prev, phoneNumber: e.target.value }))}
    >
        {(inputProps) => <Input {...inputProps} />}
    </InputMask>
</div>
<div>
<span style={{ display: 'inline-block', width: '30%' }}>Valor Despacho:</span>
<Input style={{ width: '70%' }}
            placeholder=""
            value={editableData.selectedValue?.value}
            onChange={e => setEditableData(prev => ({ ...prev, selectedValue: { ...prev.selectedValue, value: e.target.value } }))}
        />
</div>
<div>
    <span style={{ display: 'inline-block', width: '30%' }}>Sector:</span>
    <Select 
        style={{ width: '70%' }} 
        value={editableData.selectedValue?.sector}
        onChange={value => setEditableData(prev => ({ ...prev, selectedValue: { ...prev.selectedValue, sector: value } }))}
    >
        <Select.Option value="Viña del Mar">Viña del Mar</Select.Option>
        <Select.Option value="Valparaiso">Valparaiso</Select.Option>
        <Select.Option value="Con Con">Con Con</Select.Option>
        <Select.Option value="Reñaca">Reñaca</Select.Option>
        <Select.Option value="Quilpue">Quilpue</Select.Option>
        <Select.Option value="Belloto">Belloto</Select.Option>
        <Select.Option value="Villa Alemana">Villa Alemana</Select.Option>
        <Select.Option value="Peña Blanca">Peña Blanca</Select.Option>
        <Select.Option value="Otro">Otro</Select.Option>
        
    </Select>
</div>
<div>
    <span style={{ display: 'inline-block', width: '30%' }}>Notas:</span>
    <Input
        style={{ width: '70%' }}
        placeholder="Notas"
        value={editableData.notes}
        onChange={e => setEditableData(prev => ({ ...prev, notes: e.target.value }))}
    />
</div>
<div>
<Popconfirm
  title="Confirmar cambios"
  onConfirm={handleSave}
  onCancel={handleCancel} // you can add a function to handle the cancel action, or omit this if not needed
  okText="Yes"
  cancelText="No"
>
  <Button type="link" style={{ float: 'right' }}><EditFilled /></Button>
</Popconfirm>

    </div>
    </div>
);
};

export default OrderComponent;






