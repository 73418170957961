
import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Card, Table, Button, Pagination, Modal, Input, Space, Popconfirm } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import EditProduct from '../components/productos/editProduct'; // Import the EditProduct component
import Productos from '../components/productos/productos';

export default function ProductList() {
  const [products, setProducts] = useState([]);
  const [visible, setVisible] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [newProductModalVisible, setNewProductModalVisible] = useState(false);
  const [searchText, setSearchText] = useState('');
const [searchedColumn, setSearchedColumn] = useState('');
const searchInput = useRef(null);
const [currentPage, setCurrentPage] = useState(1);
const [itemsPerPage, setItemsPerPage] = useState(5); // set your desired items per page


const getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={searchInput}
        placeholder={`Buscar ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Buscar
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => searchInput.current.select(), 100);
    }
  },
});


const handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  setSearchText(selectedKeys[0]);
  setSearchedColumn(dataIndex);
};

const handleReset = clearFilters => {
  clearFilters();
  setSearchText('');
};


  const openNewProductModal = () => {
    setNewProductModalVisible(true);
  };

  const closeNewProductModal = () => {
    setNewProductModalVisible(false);
    getProducts(); // Re-fetch the products after closing the new product modal
  };

   async function getProducts() {
    const response = await fetch(`${process.env.REACT_APP_NODE_END_POINT}/productos/`);

    if (!response.ok) {
      const message = `An error occurred: ${response.statusText}`;
      window.alert(message);
      return;
    }

    const products = await response.json();
    setProducts(products);
  }

  useEffect(() => {
    getProducts();
  }, []);

  async function deleteProduct(id) {
    await fetch(`${process.env.REACT_APP_NODE_END_POINT}/productos/${id}`, {
      method: "DELETE"
    });

    getProducts(); // Re-fetch the products after deletion
  }

  const openModal = (product) => {
    setCurrentProduct(product);
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
    setCurrentProduct(null);
    getProducts(); // Re-fetch the products after closing the modal
  };

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Etiqueta',
      dataIndex: 'label',
      key: 'label',
      ...getColumnSearchProps('label'),
    },
    {
      title: 'Variantes',
      dataIndex: 'variants',
      key: 'variants',
      render: (variants) => 
        variants.map((variant, index) => (
          <div key={index}>
            {`${variant.name}:  $${variant.price}`}
          </div>
        ))
    },
    {
      title: 'Modificar',
      key: 'action',
      render: (product) => (
        <>
        
        <Button type="primary" onClick={() => openModal(product)}>
          Editar
        </Button>
        {" | "}
        <Popconfirm
    title="Confirmar Eliminación?"
    onConfirm={() => deleteProduct(product._id)}
    onCancel={() => console.log('User canceled delete operation')}
    okText="Si"
    cancelText="No"
>
    <Button type="dashed" danger>
        Borrar
    </Button>
</Popconfirm>

      
      </>
      
      ),
    }
  ];

  return (
<div className="tabled">
      <Row gutter={[24, 0]}>
        <Col lg={24} md={24} sm={24} xs={24}>
          <Card title={
            <>
              Lista Productos
              <Button type="primary" onClick={openNewProductModal} style={{ marginLeft: '16px' }}>
                Crear Nuevo Producto
              </Button>
            </>
          }>
        <Table
  columns={columns}
  dataSource={products}
  rowKey={product => product._id}
  pagination={{
    current: currentPage,
    pageSize: itemsPerPage,
    total: products.length,
    showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} productos`,
    onChange: (page, pageSize) => {
      setCurrentPage(page);
      setItemsPerPage(pageSize);
    },
    showSizeChanger: true,
    pageSizeOptions: ['5', '10', '20', '50', '100'],
  }}
/>

          </Card>
        </Col>
      </Row>

      <Modal
        title="Editar Producto"
        visible={visible}
        onCancel={closeModal}
        footer={null}
      >
        {currentProduct && (
          <EditProduct 
            id={currentProduct._id} 
            closeModal={closeModal}
          />
        )}
      </Modal>

      <Modal
        title="Crear Nuevo Producto"
        visible={newProductModalVisible}
        onCancel={closeNewProductModal}
        footer={null}
      >
        <Productos closeNewProductModal={closeNewProductModal} />
      </Modal>

    </div>
  );
}
