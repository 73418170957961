import React, { useEffect } from 'react';
import { Form, Input, Button } from 'antd';

// EDITAR NEGOCIOS (Businesses)
function Edit({ record, handleUpdate, closeModal, onClose }) {
  const [form] = Form.useForm();
  const id = record?._id;

  useEffect(() => {
    if (!id) return;

    async function fetchData() {
      try {
        const response = await fetch(`${process.env.REACT_APP_NODE_END_POINT}/businesses/${id}`);

        if (!response.ok) {
          const message = `An error has occurred: ${response.statusText}`;
          window.alert(message);
          return;
        }

        const businessData = await response.json();

        form.setFieldsValue({
          rutEmpresa: businessData.rutEmpresa,
          nombreFantasia: businessData.nombreFantasia,
          numeroContacto: businessData.numeroContacto,
          correoContacto: businessData.correoContacto,
          razonSocial: businessData.razonSocial,
          giroComercial: businessData.giroComercial,
          direccionComercial: businessData.direccionComercial
        });
      } catch (error) {
        console.error("Error fetching data:", error);
        window.alert("An unexpected error occurred. Please try again.");
      }
    }

    fetchData();
  }, [id, closeModal, form]);

  async function onSubmit(values) {
    const editedBusiness = {
      _id: id,
      ...values
    };
    
    const response = await fetch(`${process.env.REACT_APP_NODE_END_POINT}/businesses/${id}`, {
      method: "PATCH",
      body: JSON.stringify(editedBusiness),
      headers: {
        'Content-Type': 'application/json'
      },
    });

    if (response.ok) {
      if (typeof handleUpdate === 'function') {
        handleUpdate(editedBusiness);
      }
    }
    onClose(); // Close the modal
  }

  const formItemLayout = { labelCol: { span: 4 }, wrapperCol: { span: 14 } };
  const buttonItemLayout = { wrapperCol: { span: 14, offset: 4 } };

  return (
    <Form
      {...formItemLayout}
      layout="horizontal"
      form={form}
      initialValues={{
        rutEmpresa: record ? record.rutEmpresa : "",
        nombreFantasia: record ? record.nombreFantasia : "",
        numeroContacto: record ? record.numeroContacto : "",
        correoContacto: record ? record.correoContacto : "",
        razonSocial: record ? record.razonSocial : "",
        giroComercial: record ? record.giroComercial : "",
        direccionComercial: record ? record.direccionComercial : ""
      }}
      onFinish={onSubmit}
      style={{ maxWidth: 600 }}
    >
           <Form.Item label="RUT Empresa" name="rutEmpresa" rules={[{ required: true, message: 'Please input RUT Empresa!' }]}>
        <Input />
      </Form.Item>
      <Form.Item label="Nombre Fantasia" name="nombreFantasia" rules={[{ required: true, message: 'Please input Nombre Fantasia!' }]}>
        <Input />
      </Form.Item>
      <Form.Item label="Número de Contacto" name="numeroContacto" rules={[{ required: true, message: 'Please input Número de Contacto!' }]}>
        <Input />
      </Form.Item>
      <Form.Item label="Correo de Contacto" name="correoContacto" rules={[{ required: true, message: 'Please input a valid email!', type: 'email' }]}>
        <Input />
      </Form.Item>
      <Form.Item label="Razón Social" name="razonSocial" rules={[{ required: true, message: 'Please input Razón Social!' }]}>
        <Input />
      </Form.Item>
      <Form.Item label="Giro Comercial" name="giroComercial" rules={[{ required: true, message: 'Please input Giro Comercial!' }]}>
        <Input />
      </Form.Item>
      <Form.Item label="Dirección Comercial" name="direccionComercial" rules={[{ required: true, message: 'Please input Dirección Comercial!' }]}>
        <Input />
      </Form.Item>

      <Form.Item {...buttonItemLayout}>
        <Button type="primary" htmlType="submit">
          Actualizar Negocio
        </Button>
      </Form.Item>
    </Form>
  );
}

export default Edit;
